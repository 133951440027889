import { Col, Nav, Card, Button, Image } from "react-bootstrap";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import Logo from "../../../../assets/images/logo.png";
import Profile from "../../../../assets/images/avatar.png";
import { useState } from "react";
import useAuth from "../../../../core/hook/useAuth";
import useLogout from "../../../../core/hook/useLogout";
import { AppConstants } from "../../../../core/app-constants";

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();
  const { auth } = useAuth();
  const [userName] = useState(
    `${auth?.user?.firstName} ${auth?.user?.lastName}`
  );

  const handleSignOut = () => {
    logout();
    navigate("/auth/login", { replace: true });
  };

  return (
    <Col
      lg="2"
      className="d-none d-sm-block d-md-none d-lg-block bg-white border-end shadow-lg sidebar-parent position-fixed p-0"
    >
      <Card className="min-vh-100 rounded-0 border-0">
        <Card.Body className="p-0 m-0">
          <div className="side-bar">
            <div className="text-start mt-5">
              <Image
                alt="img"
                src={Logo}
                style={{ objectFit: "contain", width: "70%" }}
              />
            </div>

            <Nav
              variant="pills"
              className="flex-column side-nav"
              defaultActiveKey={location.pathname}
            >
              {AppConstants.sideBarMenu.map((menuItem, key) => {
                return (
                  <Nav.Item key={key}>
                    <Nav.Link as={NavLink} to={menuItem.route}>
                      <menuItem.icon className="me-2 fs-5" /> {menuItem.title}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>
        </Card.Body>

        <Card.Footer className="border-0 text-center card-bg py-5">
          <Image
            alt="img"
            src={Profile}
            className="border-0 rounded-circle mb-3"
            width="80"
          />
          <h5 className="text-white fw-semibold">{userName}</h5>
          <Button
            className="border-0 text-light w-50 shadow-lg"
            onClick={handleSignOut}
          >
            Log out
          </Button>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default SideBar;
