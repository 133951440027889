import { useEffect, useState } from "react";

const getStoredData = (key: string, initialValue: any) => {
  if (typeof window == "undefined") return initialValue;

  const storedValue = sessionStorage.getItem(key);
  if (storedValue) return JSON.parse(storedValue);

  if (initialValue instanceof Function) return initialValue();

  return initialValue;
};

const useSessionStorage = (key: string, initialValue: any) => {
  const [value, setValue] = useState(() => getStoredData(key, initialValue));

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useSessionStorage;
