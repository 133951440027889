import React from "react";
import { Container, Spinner } from "react-bootstrap";
import { PageTitle } from "../../../shared/components";
import {BiPlus} from 'react-icons/bi'
import CommissionTable from "./components/CommissionTable";
import { useGetAllCommissionQuery } from "../../../../services/commission/commission.service";

function Commissions() {
  const [modalShow, setModalShow] = React.useState(false);
  const {isLoading,data} = useGetAllCommissionQuery();
  console.log("com-data:",data)

  return (
    <section className="main-page">
      <Container fluid className="px-5 pt-5">
        <PageTitle
          title="Commissions"
          hasCTA={false}
          ctaLabel="Add Commission"
          ctaIcon={<BiPlus />}
          ctaAction={()=>setModalShow(false)}
        />
        <div className="glass-bg-datable mt-2">
        {isLoading ? (
            <Spinner />
          ) : (
            <CommissionTable commissionData={data?.data} />
            )}
        </div>
      </Container>
    </section>
  );
}

export default Commissions;
