import { Button, Row, Col, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Logo from "../../../../../../assets/images/logo.png";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import File from "../../../../../../assets/images/file.gif";
import { FC, useState } from "react";
import AddIndividualForm from "../forms/AddIndividualForms";
import { CustomerRequest } from "../../../../../../model/request/customer.request";
import AddOrganizationForm from "../forms/AddOrganizationForm";
import {
  useBulkCustomerMutation,
  useUpdateCustomerMutation,
} from "../../../../../../services/customer/customer.service";
import Papa from "papaparse";
import { toast } from "react-toastify";

type AddCustomerModalProps = {
  show: boolean;
  onHide: () => void;
  isEditMode?: boolean | false;
  selectedCustomer?: CustomerRequest;
  onAddCustomer: (customer: CustomerRequest) => void;
};
// type CsvData = {
//   name: string;
//   msisdn: string;
//   ghanaCardNumber: string;
//   email: string;
//   dateOfBirth: string;
//   certificateOfIncorporationNumber: string;
//   customerType: string;
// };

const AddCustomerModal: FC<AddCustomerModalProps> = (props) => {
  const { selectedCustomer, isEditMode } = props;
  const { show, onHide } = props;
  const [updateCustomer] = useUpdateCustomerMutation();
  const [bulkCustomer] = useBulkCustomerMutation();
  // const [bulkCustomersData, setBulkCustomersData] = useState<CustomerRequest[]>(
  //   []
  // );
  const [file, setFile] = useState();
  const handleUpdateCustomer = async (customer: CustomerRequest) => {
    try {
      console.log("update customer: ", customer);
      await updateCustomer(customer);
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const handleAddIndividual = (customer: CustomerRequest) => {
    if (isEditMode === true) {
      handleUpdateCustomer(customer);
    } else {
      customer.customerType = "INDIVIDUAL";
      // console.log("Individual: ", customer);
      props.onAddCustomer(customer);
    }
  };

  const handleAddOrganization = (customer: CustomerRequest) => {
    if (isEditMode === true) {
      handleUpdateCustomer(customer);
    } else {
      customer.customerType = "COMPANY";
      // console.log("Organization: ", customer);
      props.onAddCustomer(customer);
    }
  };

  const isHideTab = (customerType?: string) => {
    if (selectedCustomer) {
      return selectedCustomer?.customerType?.toLowerCase() !== customerType;
    }

    return false;
  };

  const buttonLabel = isEditMode ? "Update" : "Add";
  const fileReader = new FileReader();

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };
  
  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
  
    if (file) {
      fileReader.onload = async function (event) {
        const csvData = event.target?.result as string;
        console.log("File data:", csvData);
        try {
          const jsonData: CustomerRequest[] = await convertCsvToJson(csvData);
          console.log("json", jsonData);
          const response = await bulkCustomer(jsonData);
          console.log("Bulk upload response:", response);
          // Handle the response as needed
        } catch (error) {
          console.log("Bulk upload error:", error);
          // Handle the error as needed
        }
      };
  
      fileReader.readAsText(file);
    }
  };
  
  const convertCsvToJson = (csvData: string): Promise<CustomerRequest[]> => {
    return new Promise((resolve, reject) => {
      Papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.data && results.data.length > 0) {
            try {
              const jsonData: CustomerRequest[] = results.data.map(
                (item: any) => {
                  const customer: CustomerRequest = {
                    name: item.name ? item.name.trim() : "",
                    msisdn: item.msisdn ? item.msisdn.trim() : "",
                    ghanaCardNumber: item.ghanaCardNumber
                      ? item.ghanaCardNumber.trim()
                      : "",
                    email: item.email ? item.email.trim() : "",
                    dateOfBirth: item.dateOfBirth ? item.dateOfBirth.trim() : "",
                    customerType: item.customerType
                      ? item.customerType.trim()
                      : "",
                  };
                  return customer;
                }
              );
              resolve(jsonData);
            } catch (error) {
              toast("Error parsing CSV data.")
            }
          } else {
            reject(new Error("CSV data is empty."));
            toast("CSV data is empty.")
          }
        },
        error: (error: any) => {
          reject(new Error("Error parsing CSV data."));
          toast("Error parsing CSV data.")
        },
      });
    });
  };


  const downloadSampleFile = () => {
    const csvData = [
      ["name", "msisdn", "ghanaCardNumber", "email", "dateOfBirth","customerType"],
      ["John", "5551234567", "GHA20000000000001", "johndoe@example.com", "1990-01-01", "", "INDIVIDUAL"],
      ["Jane", "5559876543", "GHA20000000000002", "janesmith@example.com", "1992-05-10", "", "INDIVIDUAL"],
      ["Acme", "5555555555", "", " GHA20000000000003", "info@acme.com", "1992-05-11", "COMPANY"]
    ];
  
    let csvContent = "data:text/csv;charset=utf-8,";
  
    csvData.forEach(row => {
      csvContent += row.join(",") + "\n";
    });
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sample.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="modal-container">
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
        <Modal.Header closeButton className="border-0 w-100"></Modal.Header>
        <Modal.Body className="modal-body-style p-5">
          <Tab.Container id="tab-container" defaultActiveKey="forms">
            <Row className="align-items-top">
              <Col>
                <Image src={Logo} width={150} />
              </Col>
              <Col md="auto" hidden={isEditMode}>
                <Nav variant="pills">
                  <Nav.Item className="nav-item-tabs">
                    <Nav.Link eventKey="forms" className="fw-semibold fs-6">
                      ADD CUSTOMER
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="nav-item-tabs">
                    <Nav.Link eventKey="uploader" className="fw-semibold fs-6">
                      ADD BULK CUSTOMER
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>

            <div className="modal-form mt-5">
              <Tab.Content>
                <Tab.Pane eventKey="forms">
                  <Tab.Container
                    id="tab-container"
                    defaultActiveKey={
                      isEditMode
                        ? selectedCustomer?.customerType?.toLowerCase()
                        : "individual"
                    }
                  >
                    <Row className="tabs-style">
                      <Col>
                        <Nav variant="pills" justify={true}>
                          <Nav.Item
                            className=" nav-item-tabs"
                            hidden={isHideTab("individual")}
                          >
                            <Nav.Link
                              eventKey="individual"
                              className="fw-semibold fs-6"
                            >
                              INDIVIDUAL
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item
                            className=" nav-item-tabs"
                            hidden={isHideTab("company")}
                          >
                            <Nav.Link
                              eventKey="company"
                              className="fw-semibold fs-6"
                            >
                              COMPANY
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                    </Row>

                    <Row className="mt-4">
                      <Tab.Content>
                        <Tab.Pane eventKey="individual">
                          <AddIndividualForm
                            onSubmitForm={handleAddIndividual}
                            customer={props.selectedCustomer}
                            isEditMode={true}
                            button={buttonLabel}
                            closeModal={onHide}
                          />
                        </Tab.Pane>

                        <Tab.Pane eventKey="company">
                          <AddOrganizationForm
                            onSubmitForm={handleAddOrganization}
                            customer={props.selectedCustomer}
                            isEditMode={true}
                            button={buttonLabel}
                            closeModal={onHide}
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </Row>
                  </Tab.Container>
                </Tab.Pane>

                <Tab.Pane eventKey="uploader">
                  <div className="text-center ">
                    <Image src={File} width="35%" />
                  </div>
                  <div className="text-center">
                    {/* <Nav.Link href="#">Download sample file</Nav.Link> */}
                    <Button variant="light" className="rounded-0" onClick={downloadSampleFile}>
                      Download Sample File
                    </Button>
                  </div>
                  <div className="form-group px-5">
                    <label className="form-label">Upload Bulk file</label>
                    <input
                      type="file"
                      className="form-control rounded-0 border-primary"
                      accept=".csv"
                      onChange={handleOnChange}
                      placeholder=" Upload File "
                    ></input>
                  </div>

                  <div className="text-end px-5 mt-2">
                    <Button className="rounded-0 px-5" onClick={handleOnSubmit}>
                      Add Customers
                    </Button>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddCustomerModal;
