import { Row, Col, Container } from "react-bootstrap";
import PolicyCard from "./components/PolicyCard";
import TotalCards from "./components/TotalCards";
import { StatsCard } from "./components/StatsCard";

const Dashboard = () => {
  
  return (
    <div className="w-100 main">
      <Container className="p-c-a">
        <div className="fs-3 text-bold">Dashboard</div>

        <Row className="p-0 mt-5 gx-5">
          <Col md={6}>
            <TotalCards />
          </Col>
          <Col md={6}>
            <StatsCard/>
            <PolicyCard />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
