import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const AuthLayout = () => {
  return (
    <Container fluid className="auth-bg min-vh-100">
      <ToastContainer />
      <Row className="min-vh-100 align-items-center auth-bt-bg">
        <Col className="">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default AuthLayout;
