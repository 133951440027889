
import { Container,Spinner } from "react-bootstrap";
import { PageTitle } from "../../../shared/components";
import {BiPlus} from 'react-icons/bi'
import { useGetAllPaymentsQuery } from "../../../../services/payment/payment.service";
import PaymentTable from "./component/PaymentTable";

function Payment() {
  const {isLoading, data} = useGetAllPaymentsQuery();


  return (
    <section className="main-page">
      <Container fluid className="px-5 pt-5">
        <PageTitle
          title="Payments"
          hasCTA={false}
          ctaLabel=""
          ctaIcon={<BiPlus />}
        />
        <div className="glass-bg-datable mt-2">
        {isLoading ? (
            <Spinner />
          ) : (
            <PaymentTable paymentData={data?.data}/>
          )}
        </div>
      </Container>
    </section>
  );
}

export default Payment;
