import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../../core/app-constants";
import { BaseResponse } from "../../model/response/base-response";
import { PaymentResponse } from "../../model/response/payment.response";


export const paymentService = createApi({
    reducerPath: "paymentService",
    baseQuery: fetchBaseQuery({
      baseUrl: `${AppConstants.baseUrl}`,
      // headers: { "Content-Type": "application/json; charset=UTF-8" },
    }),
    tagTypes: ["Payments"],
    endpoints: (builder) => ({
      getAllPayments: builder.query<BaseResponse<PaymentResponse[]>, void>({
        query: () => ({
          url: `/payments?page=1&size=1000000000`,
          method: "GET",
        }),
        providesTags: ["Payments"],
      }),    
      filterPayment: builder.mutation<BaseResponse<PaymentResponse[]>, any>({
        query: (params) => ({
          url: `/payments`+ params,
          method: "GET",
        }),
      }),  
      searchPayment: builder.mutation<BaseResponse<PaymentResponse[]>, any>({
        query: (transactionId) => ({
          url: `/payments?transactionId=${transactionId}`,
          method: "GET",
        }),
        invalidatesTags: ["Payments"],
    }),
    }),
  });
  
export const {
useGetAllPaymentsQuery,
useSearchPaymentMutation,
useFilterPaymentMutation,
} = paymentService;
