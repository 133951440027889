import { FC, ReactNode, useEffect } from "react";
import React, { useState } from "react";
import {
  FloatingLabel,
  InputGroup,
  Pagination,
  Spinner,
} from "react-bootstrap";
import {
  Form,
  Card,
  Table,
  Button,
  Image,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { PaymentResponse } from "../../../../../model/response/payment.response";
import {
  useFilterPaymentMutation,
  useSearchPaymentMutation,
} from "../../../../../services/payment/payment.service";
import FilterModal from "../modal/FilterModal";
import { AiOutlineFileExcel } from "react-icons/ai";

type PaymentTableProps = {
  paymentData?: PaymentResponse[];
};
interface CustomToggleProps {
  children: ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const PaymentTable: FC<PaymentTableProps> = (props) => {
  const { paymentData } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [searchPayment, { data }] = useSearchPaymentMutation();
  const [filteredPaymentData, setFilteredPaymentData] = useState<
    PaymentResponse[]
  >(paymentData ?? []);
  const [filterPayment, { data: filteredData }] = useFilterPaymentMutation();
  const [selectedPayment, setSelectedPayment] = useState<PaymentRequest>();
  const [filterModalShow, setFilterModalShow] = React.useState(false);
  const [filterCriteria, setFilterCriteria] = useState("");


  // ******************* pagination logics   ***********************//

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items to display per page
  const totalPages = props.paymentData?.length
    ? Math.ceil(props.paymentData.length / pageSize)
    : 0;

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const renderPageItems = () => {
    const pageItems = [];

    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  // Calculate the start and end indices based on the current page and page size
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;


  const handleSelectPayment = (payment: any) => {
    if (payment && payment.length > 0) {
      const selectedPayment = payment[0];
      setPaymentId(selectedPayment?.id);
      setSelectedPayment(selectedPayment);
      setSearchQuery(selectedPayment?.transactionId);
      console.log(selectedPayment);

      if (selectedPayment&& selectedPayment.transactionId.trim() === "") {
        const filteredData = paymentData?.filter((data) => data.id === selectedPayment.id) ?? [];
        setFilteredPaymentData(filteredData);
        console.log("selected");
      }
    }
  };

  const handleInputChange = (text: any, e: React.ChangeEvent<HTMLInputElement>): void => {
    const transactionId: string = e.target.value;
    setSearchQuery(transactionId);
  
    if (transactionId.trim() !== "") {
      // Update the filtered data based on the search query
      const filteredData = paymentData?.filter((data) =>
        data.transactionId?.toLowerCase().includes(transactionId.toLowerCase())
      ) ?? [];
      setFilteredPaymentData(filteredData);
    } else {
      // If the search query is empty, show all quote data
      setFilteredPaymentData(paymentData ?? []);
    }
  };

  const handleFilter = async () => {
    const form: Map<string, string> = new Map(Object.entries(filterCriteria));
    const queryString = formatQueryString(form);
    // Call the filterPolicy mutation with the filter criteria
    const response = await filterPayment(queryString).unwrap();
    // Update the filteredPolicyData state with the filtered data
    setFilteredPaymentData(response?.data ?? []);
    setFilterModalShow(false);
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      // If search query is empty, show all customer data
      setFilteredPaymentData(paymentData || []);
    } else {
      // If search query is not empty, filter the customer data based on the query
      const filteredData =
        paymentData?.filter((data) =>
          data?.transactionId?.includes(searchQuery)
        ) || [];
      setFilteredPaymentData(filteredData ?? []);
    }
  }, [props.paymentData, searchQuery]);

  function formatQueryString(form: Map<string, string>): string {
    let queryString: string = "";

    form.forEach((value: string, key: string) => {
      if (value !== "") {
        queryString += queryString === "" ? "?" : "&";
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}T00:00:00`;
      }
    });
    return queryString;
  }

  const exportFilteredData = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," +
      convertToCSV(filteredPaymentData);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "filtered_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data: any) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((item: any) => Object.values(item).join(","));
    return [header, ...rows].join("\n");
  };

  return (
    <div className="p-4 main-card-bg ">
      <div className="d-flex justify-content-between mb-3">
        <div className="w-25">
        <InputGroup className="mb-3">
            <FloatingLabel label="">
              <Typeahead
                id="searchPayment"
                labelKey="transactionId"
                options={paymentData || []}
                placeholder="Search by transaction ID"
                size="lg"
                onChange={handleSelectPayment}
                onInputChange={handleInputChange}
                inputProps={{
                  className: "my-custom-classname rounded-0 ",
                  style: {
                    fontSize: "15px",
                  },
                }}
              />
            </FloatingLabel>
          </InputGroup>
        </div>
        <div>
          <Button
            variant="light"
            className="px-4 shadow-lg rounded-0 border-0"
            onClick={() => setFilterModalShow(true)}
          >
            Filter
          </Button>
          <Button
            variant="light"
            className="px-4 shadow-lg mx-2 rounded-0 border-0"
            onClick={() => exportFilteredData()}
          >
            Export
          </Button>
        </div>
      </div>
      <Card className="policy-table-card bg-white border-0 rounded-0 shadow-lg ">
        <Card.Body className="table-body">
          <Table className="policy-table">
            <thead className="sticky-header py-3">
              <tr className="table-body ">
                <th className="text-start">Customer Id</th>
                <th className="text-start">Account Type</th>
                <th className="text-start">Account Holder</th>
                <th className="text-start">Account Number</th>
                <th className="text-start">Transaction Id</th>
                <th className="text-start">Asset Id</th>
                <th className="text-start">Currency</th>
                <th className="text-start">Amount</th>
                <th className="text-start">Status Description</th>
              </tr>
            </thead>
            <tbody className="text-muted table-body">
              <>{filteredPaymentData && filteredPaymentData?.length !== 0 ? (
              <>
               {filteredPaymentData?.slice(startIndex, endIndex).map((data, key) => (
               
               <tr key={key}>
                   <td>{data.customerId}</td>
                    <td>{data.accountType}</td>
                    <td>{data.accountHolder}</td>
                    <td>{data.accountNumber}</td>
                    <td>{data.transactionId}</td>
                    <td>{data.assetId}</td>
                    <td>{data.currency}</td>
                    <td>{data.amount}</td>
                    <td>{data.statusDescription}</td>
               </tr>       
             ))}
              </>
              ):
              (
                <tr>
                <td colSpan={12} className="text-center fw-normal fs-2 border-0">
                  <div>
                  <AiOutlineFileExcel className="" style={{fontSize:"100px",marginTop:"50px"}}/>
                    </div>
                 
                  No Data Found
                </td>
              </tr>
                )}
                </>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <div className="bg-light mt-2 ">
        <div className="d-flex justify-content-end ">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {renderPageItems()}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </div>
      </div>

      <FilterModal
        show={filterModalShow}
        onHide={() => setFilterModalShow(false)}
        filterCriteria={filterCriteria}
        setFilterCriteria={setFilterCriteria}
        handleFilter={handleFilter}
      />
    </div>
  
  );
};

export default PaymentTable;
