import {
  Button,
  Container,
  FloatingLabel,
  Form,
  Image,
  Spinner,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import Logo from "../../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormEvent, useState } from "react";
import { useLoginUserMutation } from "../../../services/auth/auth.service";
import { Credentials } from "../../../model/request/auth.request";
import { toast } from "react-toastify";
import useAuth from "../../../core/hook/useAuth";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuth } = useAuth();

  const [loginUser, { isLoading }] = useLoginUserMutation();
  const [credentials, setCredentials] = useState<Credentials>({
    username: "",
    password: "",
  });

  const redirectTo = location.state?.from?.pathname || "/";

  const onFormFieldChange = (e: any) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleUserLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await loginUser(credentials).unwrap();

      if ("data" in response) {
        const { data } = response;

        setAuth(data[0]);
        navigate(redirectTo, { replace: true });
      }
    } catch (e: any) {
      console.log("Error: ", e);
      if (typeof e === "object") {
        if ("data" in e) {
          const { data } = e;
          toast(data.message);
        } else if("error" in e){//33333
          const { error } = e;
          toast(error);
        }
      }
    }
  };

  return (
    <Container>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card className="p-5 border-0 shadow-lg">
            <Form className="auth-form" onSubmit={handleUserLogin}>
              <div className="text-center mb-5">
                <Image alt="Banbo Logo" src={Logo} width="40%" />
              </div>

              <Form.Group className="mb-3">
                <FloatingLabel label="Username">
                  <Form.Control
                    type="text"
                    size="lg"
                    autoComplete="off"
                    className="rounded-0 border-primary py-4"
                    name="username"
                    onChange={onFormFieldChange}
                    required
                    title="Please enter a valid username."
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3">
                <FloatingLabel label="Password">
                  <Form.Control
                    type="password"
                    size="lg"
                    autoComplete="off"
                    className="rounded-0 border-primary"
                    name="password"
                    onChange={onFormFieldChange}
                    required
                    title="Please enter a valid password."
                  />
                </FloatingLabel>
              </Form.Group>

              {isLoading ? (
                <div className="text-center">
                  <Spinner className="text-primary" />
                </div>
              ) : (
                <>
                  <div className="d-grid gap-2 mt-3">
                    <Button
                      type="submit"
                      size="lg"
                      // disabled={!credentials.username || !credentials.password}
                      className="shadow-lg rounded-0 fw-semibold fs-4"
                    >
                      Sign In
                    </Button>
                  </div>

                  <p className="text-center mt-5">
                    Forgot password?
                    <Link
                      className="text-decoration-none"
                      to="/auth/reset-password"
                    >
                      {" "}
                      Click here to reset.
                    </Link>
                  </p>
                </>
              )}
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default LoginPage;
