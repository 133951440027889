

export const InsuranceCompanies = [
   "Activa International Insurance Company-Ghana Limited",
   "Allianz Insurance Company Gh. Ltd",
    "Bedrock Insurance Company Limited",
    "Best Assurance Company Limited",
    "Coronation Insurance Ghana Limited",
    "Donewell Insurance Company Limited",
    "Enterprise Insurance Company Limited",
    "Ghana Union Assurance Limited",
    "Glico General Insurance Company Limited",
    "Heritage Energy Insurance Company Limited",
    "Hollard Insurance Ghana Limited",
    "Imperial General Assurance Company Ltd.",
    "Loyalty Insurance Company Limited",
    "Millennium Insurance Company Limited",
    "NSIA Insurance Company Limited",
    "Phoenix Insurance Company Limited",
    "Prime Insurance Company Limited",
    "Priority Insurance Company Limited",
    "Provident Insurance Company Limited",
    "Quality Insurance Company Limited",
    "Regency Nem Insurance Ghana Limited",
    "Sanlam Insurance Ghana Limited",
    "Serene Insurance Company",
    "SIC Insurance PLC",
    "Star Assurance Company Limited",
    "Sunu Assurances Ghana Limited",
    "Unique Insurance Company Limited",
    "Vanguard Assurance Company Limited",
    "Allianz Life Insurance Ghana Limited",
    "Beige Assure Company",
    "Donewell Life Company",
    "Enterprise Life Assurance LTD",
    "Esich Life Assurance Company Ltd.",
    "Exceed Life Assurance Company Limited",
    "First Insurance Company Limited",
    "Ghana Life Insurance Company",
    "Ghana Union Assurance Life Company",
    "Glico Life Insurance Company",
    "GN Life Assurance Limited",
    "Hollard Life Assurance Company Limited",
    "Metropolitan Life Insurance Ghana LTD",
    "MiLife Company Company Limited",
    "Old Mutual Life Assurance Company Ghana",
    "Prudential Life Insurance Ghana",
    "Quality Life Assurance Company",
    "Sanlam Life Insurance Ghana Limited",
    "SIC Life Company LTD",
    "Starlife Assurance Company Limited",
    "Vanguard Life Assurance Company Limited",
    "Acacia Health Insurance Limited",
    "Ace Medical Insurance",
    "Apex Health Insurance Limited",
    "Cosmopolitan Health Insurance Limited",
    "Dosh Health Insurance Company Ltd",
    "Equity Health Insurance Limited",
    "GLICO Healthcare Limited",
    "Metropolitan Health Insurance Ghana Limited",
    "NMH Nationwide Medical Health Insurance Scheme Limited",
    "Phoenix Health Insurance",
    "Premier Health Insurance Company Limited",
    "Vitality Health Systems Limited"
  ];


//  default InsuranceCompanies;