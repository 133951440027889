import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../../core/app-constants";
import { BaseResponse } from "../../model/response/base-response";
import { ClaimRequest} from "../../model/request/claim.request";
import { ClaimResponse } from "../../model/response/claim.response";

export const claimService = createApi({
    reducerPath: "claimService",
    baseQuery: fetchBaseQuery({
      baseUrl: `${AppConstants.baseUrl}`,
      // headers: { "Content-Type": "application/json; charset=UTF-8" },
    }),
    tagTypes: ["Claims"],
    endpoints: (builder) => ({
      getAllClaims: builder.query<BaseResponse<ClaimResponse[]>, void>({
        query: () => ({
          url: "/claims?page=1&size=1000000000",
          method: "GET",
        }),
        providesTags: ["Claims"],
      }),
      addClaim: builder.mutation<BaseResponse<ClaimRequest[]>, ClaimRequest>({
        query: (claim) => ({
          url: "/claims",
          method: "POST",
          body: claim,
        }),
        invalidatesTags: ["Claims"],
      }),
      deleteClaim: builder.mutation<BaseResponse<ClaimRequest[]>, any>({
        query: (claimId) => ({
          url: `/claims/${claimId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Claims"],
    }),
    getClaimById: builder.mutation<BaseResponse<ClaimResponse[]>, any>({
      query: (claimId) => ({
        url: `/customers/${claimId}`,
        method: "GET",
      }),
      invalidatesTags: ["Claims"],
    }),
    updateClaimById: builder.mutation<BaseResponse<ClaimResponse[]>, ClaimRequest>({
      query: (claim) => ({
        url: `/claims`,
        method: "PUT",
        body: claim,
      }),
      invalidatesTags: ["Claims"],
    }),
    searchClaim: builder.mutation<BaseResponse<ClaimResponse[]>, any>({
      query: (insuranceCompany) => ({
        url: `/claims?insuranceCompany=${insuranceCompany}`,
        method: "GET",
      }),
      invalidatesTags: ["Claims"],
  }),
    filterClaim: builder.mutation<BaseResponse<ClaimResponse[]>, any>({
      query: (params) => ({
        url: `/claims`+ params,
        method: "GET",
      }),
      invalidatesTags: ["Claims"],
    }),
    }),
  });
  
export const {
useGetAllClaimsQuery,
useGetClaimByIdMutation,
useSearchClaimMutation,
useAddClaimMutation,
useFilterClaimMutation,
useDeleteClaimMutation,
useUpdateClaimByIdMutation,
}= claimService;
