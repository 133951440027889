import { createContext, useState, useEffect } from "react";
import { useSessionStorage } from "../hook";

type AuthContextType = {
  auth: any;
  setAuth: React.Dispatch<React.SetStateAction<any>>;
};

const AuthContext = createContext<AuthContextType>({
  auth: {},
  setAuth: () => {},
});

export const AuthProvider = ({ children }: any) => {
  const [cachedUser, setCachedUser] = useSessionStorage("user", {});
  const [auth, setAuth] = useState(cachedUser);

  useEffect(() => {
    setCachedUser(auth);
  }, [auth, setCachedUser]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
