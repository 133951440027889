import {
  Form,
  Button,
  Row,
  Col,
  Modal,
  Image,
  FloatingLabel,
} from "react-bootstrap";
import Logo from "../../../../../../assets/images/logo.png";
import { ChangeEvent, FC, useEffect, useState } from "react";
import {PolicyType} from "../../../../../../model/response/policy-type.response"
import { InsuranceCompanies } from "../../../../../shared/components/InsuranceCompanies";
import { usePolicyTypesLsitQuery } from "../../../../../../services/policy/policy.service";
import { Leads } from "../../../../../shared/components/Leads";

interface FilterAndExportPolicyProps {
  show: boolean;
  onHide: () => void;
  filterCriteria: any;
  setFilterCriteria: any;
  handleFilter: () => void;
}

const FilterAndExportPolicy: FC<FilterAndExportPolicyProps> = (props) => {
  const { setFilterCriteria, filterCriteria } = props;
  const { data: policyTypeData } = usePolicyTypesLsitQuery();
  const [showPolicyTypes, setShowPolicyTypes] = useState<PolicyType[]>();
  const handleApplyFilter = () => {
    // Update the filterCriteria state with the generated queryString
    props.handleFilter();
  };

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterCriteria({
      ...filterCriteria,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setShowPolicyTypes(policyTypeData?.data);
    console.log("show", showPolicyTypes);
  }, [policyTypeData, showPolicyTypes]);

  return (
    <div className="modal-container">
      <Modal
        {...props}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
        <Modal.Header closeButton className="border-0 w-100"></Modal.Header>
        <Modal.Body className="modal-body-style">
          <div className="d-flex justify-content-between px-5 align-items-center header-with-logo-and-modal-title">
            <div>
              <Image alt="img" src={Logo} width={150} />
            </div>
            <div className="text-end">
              <span className="text-primary fs-5 fw-semibold">
                Filter and Export Policy{" "}
              </span>
            </div>
          </div>
          <div className="modal-form mt-3">
            <Form className="modal-forms mt-5">
              <Row className="px-5 mb-2">
                <Form.Group as={Col}>
                  <FloatingLabel label="Policy Type">
                    <Form.Control
                      as="select"
                      type="text"
                      name="policyType"
                      className="rounded-0 border-primary"
                      onChange={handleFormChanged}
                      value={filterCriteria.PolicyType}
                    >
                      {showPolicyTypes &&
                        showPolicyTypes?.map((policy: any, index: any) => (
                          <option key={index} value={policy.value}>
                            {" "}
                            {policy.displayName}{" "}
                          </option>
                        ))}
                    </Form.Control>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col}>
                  <FloatingLabel label="Transaction Type">
                    <Form.Control
                      as="select"
                      type="text"
                      className="rounded-0 border-primary"
                      name="transactionType"
                      value={filterCriteria.transactionType}
                      onChange={handleFormChanged}
                    >
                      <option value="" selected disabled> -- Select Transaction Type --</option>
                      <option value="New Business">New Business</option>
                      <option value="Renewal">Renewal</option>
                    </Form.Control>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="px-5 mb-3">
                <Form.Group as={Col}>
                  <FloatingLabel label="Transaction Currency">
                    <Form.Control
                      as="select"
                      type="text"
                      name="transactionCurrency"
                      className="rounded-0 border-primary"
                      onChange={handleFormChanged}
                      value={filterCriteria.transactionCurrency}
                    >
                      <option value="" selected disabled>
                        -- Select Transaction Currency --
                      </option>
                      <option value="USD">USD</option>
                      <option value="GHS">GHS</option>
                      <option value="EUR">EUR</option>
                      <option value="GBP">GBP</option>
                    </Form.Control>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col}>
                  <FloatingLabel label="Client Classification">
                    <Form.Control
                      as="select"
                      type="text"
                      className="rounded-0 border-primary"
                      name="clientClassification"
                      value={filterCriteria.clientClassififcation}
                      onChange={handleFormChanged}
                    >
                      <option value="" selected disabled>
                        {" "}
                        -- select Client Classification --{" "}
                      </option>
                      <option value="Retail"> Retail</option>
                      <option value="Corporate">Corporate </option>
                      <option value="Digital">Digital </option>
                    </Form.Control>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="px-5">
                <Form.Group as={Col}>
                  <FloatingLabel label="Lead">
                    <Form.Control
                      as="select"
                      type="text"
                      className="rounded-0 border-primary"
                      name="lead"
                      onChange={handleFormChanged}
                      value={filterCriteria.lead}
                    >
                      <option value="" selected disabled>-- Select Lead -- </option>
                      {Leads.map((lead,index)=>(
                      <option value={index}>{lead} </option>
                     ))}
                    </Form.Control>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col}>
                  <FloatingLabel label="Insurance Company">
                    <Form.Control
                      as="select"
                      type="text"
                      name="insuranceCompany"
                      className="rounded-0 border-primary"
                      onChange={handleFormChanged}
                      value={filterCriteria.insuranceCompany}
                    >
                      <option value="" selected disabled>
                -- select insurance company --
              </option>
                      {InsuranceCompanies.map((company, index) => (
                        <option key={index}>{company}</option>
                      ))}
                    </Form.Control>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <div className="text-end px-5 mt-5">
                <Button
                  className="rounded-0 px-5"
                  onClick={() => handleApplyFilter()}
                >
                  Filter
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
    </div>
  );
};

export default FilterAndExportPolicy;
