import React, { useEffect } from "react";
import { ReactNode, useState } from "react";
import { FC } from "react";
import {
  Table,
  Card,
  Button,
  Pagination,
  Dropdown,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { AiOutlineFileExcel } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import { ClaimRequest } from "../../../../../model/request/claim.request";
import { ClaimResponse } from "../../../../../model/response/claim.response";
import {
  useDeleteClaimMutation,
  useFilterClaimMutation,
  useSearchClaimMutation,
  useUpdateClaimByIdMutation,
} from "../../../../../services/claim/claim.service";
import AddClaimModal from "./modals/AddClaimsModal";
import DeleteClaim from "./modals/DeleteClaim";
import FilterAndExportClaim from "./modals/FilterAndExportClaimsModal";

type ClaimResponseProps = {
  claimData?: ClaimResponse[];
};
interface CustomToggleProps {
  children: ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ClaimTable: FC<ClaimResponseProps> = (props) => {
  const [deleteClaim] = useDeleteClaimMutation();

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [updateClaimById] = useUpdateClaimByIdMutation();
  const [claimId, setClaimId] = useState<number | null>(null);
  const [selectedClaimId, setSelectedClaimId] = useState<number | null>(null);
  const [filterModalShow, setFilterModalShow] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchClaim] = useSearchClaimMutation();
  const [filteredClaimData, setFilteredClaimData] = useState<ClaimResponse[]>(
    props.claimData ?? []
  );
  const [selectedClaim, setSelectedClaim] = useState<ClaimRequest>();
  const [filterCriteria, setFilterCriteria] = useState("");
  const [filterClaim, ] = useFilterClaimMutation();

  const handleSelectClaim = (claim: any) => {
    if (claim && claim.length > 0) {
      const selectedClaim = claim[0];
      setClaimId(selectedClaim?.id);
      setSelectedClaim(selectedClaim);
      setSearchQuery(selectedClaim?.policyId || "");

      if (searchQuery.trim() === "") {
        const filteredData =
          props.claimData?.filter((data) => data.id === selectedClaim?.id) ??
          [];
        setFilteredClaimData(filteredData);
      }
    }
  };

  const handleInputChange = (
    text: any,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const insuranceCompany: string = e.target.value || "";
    const query = insuranceCompany.trim();

    setSearchQuery(query);
    if (query !== "") {
      searchClaim(query).unwrap();
      const filteredData =
        props.claimData?.filter(
          (data) =>
            data?.insuranceCompany &&
            data?.insuranceCompany?.toLowerCase().includes(query.toLowerCase())
        ) ?? [];
      setFilteredClaimData(filteredData);
    } else {
      setFilteredClaimData(props.claimData ?? []);
    }
  };

  useEffect(() => {
    if (!searchQuery.trim()) {
      // If search query is empty or only whitespace, show all claim data
      setFilteredClaimData(props.claimData ?? []);
    } else {
      // If search query is not empty, filter the claim data based on the query
      const filteredData =
        props.claimData?.filter(
          (data) =>
            data?.insuranceCompany &&
            data.insuranceCompany.includes(searchQuery)
        ) ?? [];
      setFilteredClaimData(filteredData);
    }
  }, [props.claimData, searchQuery]);

  function formatQueryString(form: Map<string, string>): string {
    let queryString: string = "";

    form.forEach((value: string, key: string) => {
      if (value !== "") {
        queryString += queryString === "" ? "?" : "&";
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}`;
      }
    });

    return queryString;
  }

  const handleFilter = async () => {
    const form: Map<string, string> = new Map(Object.entries(filterCriteria));
    const queryString = formatQueryString(form);
    // Call the filterPolicy mutation with the filter criteria
    const response = await filterClaim(queryString).unwrap();
    // Update the filteredPolicyData state with the filtered data
    setFilteredClaimData(response?.data ?? []);
    setFilterModalShow(false);
  };

  const handleDeleteAction = (claim: ClaimRequest, claimID: number) => {
    setSelectedClaimId(claimID);
    setDeleteModalShow(true); // Show the delete modal
  };

  const handleEditAction = (claim: ClaimRequest, claimID: number) => {
    setSelectedClaim(claim);
    setSelectedClaimId(claimID);
    setEditModalShow(true); // Show the edit modal
  };

  const handleConfirmDelete = async () => {
    try {
      let response = await deleteClaim(selectedClaimId);
      toast("Claim deleted successfully");
      setSelectedClaimId(null);
      setDeleteModalShow(false);
    } catch (error) {
      toast(`Error deleting claim:, ${error}`);
    }
    // setSelectedCustomerId(null);
  };

  const handleCancelDelete = () => {
    setSelectedClaimId(null);
    setDeleteModalShow(false);
  };

  const CustomToggle = React.forwardRef<HTMLButtonElement, CustomToggleProps>(
    ({ children, onClick }, _ref) => (
      <div className="position-relative">
        <Button variant="link" onClick={onClick} className="p-0">
          <BsThreeDotsVertical size={25} />
          {children}
        </Button>
      </div>
    )
  );

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items to display per page
  const totalPages = props.claimData?.length
    ? Math.ceil(props.claimData.length / pageSize)
    : 0;

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const renderPageItems = () => {
    const pageItems = [];

    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const handleUpdateClaim = async (claim: ClaimRequest) => {
    try {
      await updateClaimById(claim);
    } catch (e) {
      toast(`Error: e`);
    }
  };

  const exportFilteredData = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredClaimData);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "filtered_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data: any) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((item: any) => Object.values(item).join(","));
    return [header, ...rows].join("\n");
  };

  return (
    <div className="p-4 main-card-bg ">
      <div className="d-flex justify-content-between mb-3">
        <div className="w-25">
          <InputGroup className="mb-3">
            <FloatingLabel label="">
              <Typeahead
                id="searchClaim"
                labelKey="insuranceCompany"
                options={props.claimData || []}
                placeholder="Search by insurance company"
                size="lg"
                onChange={handleSelectClaim}
                onInputChange={handleInputChange}
                inputProps={{
                  className: "my-custom-classname rounded-0 ",
                  style: {
                    fontSize: "15px",
                  },
                }}
              />
            </FloatingLabel>
          </InputGroup>
        </div>
        <div>
          <Button
            variant="light"
            className="px-4 shadow-lg rounded-0 border-0 "
            onClick={() => setFilterModalShow(true)}
          >
            Filter
          </Button>
          <Button
            variant="light"
            className="px-4 shadow-lg mx-2 rounded-0 border-0 "
            onClick={() => exportFilteredData()}
          >
            Export
          </Button>
        </div>
      </div>
      <Card className="policy-table-card bg-white border-0 rounded-0 shadow-lg ">
        <Card.Body>
          <Table className="policy-table">
            <thead className="sticky-header py-3">
              <tr className="table-body">
                <th>Customer Name </th>
                <th>Classification</th>
                <th>Insurance Company</th>
                <th>Status</th>
                <th>Date of Request</th>
                <th>Date of Incident</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>

            <tbody className="text-muted table-body">
              <>
                {filteredClaimData && filteredClaimData?.length !== 0 ? (
                  <>
                    {filteredClaimData
                      ?.slice(startIndex, endIndex)
                      .map((data, key) => (
                        <tr className="" key={key}>
                          <td>{data.customer?.name}</td>
                          <td>{data.clientClassification}</td>
                          <td>{data.insuranceCompany}</td>
                          <td>{data.currentStatus}</td>
                          <td>{data.dateOfRequest}</td>
                          <td>{data.dateOfIncident}</td>
                          <td className="text-end">
                            <Dropdown>
                              <Dropdown.Toggle
                                as={CustomToggle}
                              ></Dropdown.Toggle>
                              <Dropdown.Menu
                                title=""
                                className="dropdown-menu position-absolute"
                              >
                                <Dropdown.Item
                                  onClick={() =>
                                    handleEditAction(
                                      data as ClaimRequest,
                                      data.id
                                    )
                                  }
                                >
                                  Edit Claim
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    handleDeleteAction(
                                      data as ClaimRequest,
                                      data.id
                                    )
                                  }
                                >
                                  Delete Claim
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={12}
                      className="text-center fw-normal fs-2 border-0"
                    >
                      <div>
                        <AiOutlineFileExcel
                          className=""
                          style={{ fontSize: "100px", marginTop: "50px" }}
                        />
                      </div>
                      No Data Found
                    </td>
                  </tr>
                )}
              </>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <div className="bg-light mt-2 ">
        <div className="d-flex justify-content-end ">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {renderPageItems()}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </div>
      </div>

      <FilterAndExportClaim
        show={filterModalShow}
        onHide={() => setFilterModalShow(false)}
        filterCriteria={filterCriteria}
        setFilterCriteria={setFilterCriteria}
        handleFilter={handleFilter}
      />
      <DeleteClaim
        show={deleteModalShow}
        onHide={() => handleCancelDelete()}
        onDelete={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
      <AddClaimModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        onAddClaim={handleUpdateClaim}
        selectedClaim={selectedClaim}
        isEditMode={true}
      />
    </div>
  );
};

export default ClaimTable;
