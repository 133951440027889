import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import Startup from "./Startup";
import { Provider } from "react-redux";
import { store } from "./core/store";
import { AuthProvider } from "./core/context/AuthProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <Startup />
      </AuthProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
