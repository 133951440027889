import { ChangeEvent } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Image,
  Modal,
  FloatingLabel,
} from "react-bootstrap";
import Logo from "../../../../../assets/images/logo.png";

interface ModalType {
  show: boolean;
  onHide: () => void;
  filterCriteria: any;
  setFilterCriteria: any;
  handleFilter: () => void;
}

function FilterPayment(props: ModalType) {
  const { setFilterCriteria, filterCriteria } = props;
  const handleApplyFilter = () => {
    props.handleFilter();
  };

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterCriteria({
      ...filterCriteria,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="modal-container">
      <Modal
        {...props}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
        <Modal.Header closeButton className="border-0 w-100"></Modal.Header>
        <Modal.Body className="modal-body-style">
          <div className="d-flex justify-content-between px-5 align-items-center header-with-logo-and-modal-title">
            <div>
              <Image src={Logo} width={150} />
            </div>
            <div className="text-end">
              <span className="text-primary fs-5 fw-semibold">
                Filter and Export Payment{" "}
              </span>
            </div>
          </div>
          <div className="modal-form mt-3">
            <Form className="modal-forms mt-5">
              <Row className="px-5">
                <Form.Group as={Col}>
                  <FloatingLabel label="Start Date">
                    <Form.Control
                      type="date"
                      name="minDate"
                      className="rounded-0 border-primary"
                      onChange={handleFormChanged}
                      value={filterCriteria.minData}
                    ></Form.Control>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col}>
                  <FloatingLabel label=" End Date">
                    <Form.Control
                      type="date"
                      name="maxDate"
                      className="rounded-0 border-primary"
                      onChange={handleFormChanged}
                      value={filterCriteria.maxData}
                    ></Form.Control>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <div className="text-end px-5 mt-5">
                <Button
                  className="rounded-0 px-5"
                  onClick={() => handleApplyFilter()}
                >
                  Filter
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
    </div>
  );
}

export default FilterPayment;
