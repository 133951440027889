
import { Container,Spinner } from "react-bootstrap";
import { PageTitle } from "../../../shared/components";
import {BiPlus} from 'react-icons/bi'
import QuoteDataTable from "./components/QuoteDataTable";
import { useGetAllQuotesQuery } from "../../../../services/quote/quote.service";

function Quote() {

  const {isLoading,data} = useGetAllQuotesQuery();

  return (
    <section className="main-page">
      <Container fluid className="px-5 pt-5">
        <PageTitle
          title="Quote"
          hasCTA={false}
          ctaLabel="Add Claim"
          ctaIcon={<BiPlus />}
        />
        <div className="glass-bg-datable mt-2">
        {isLoading ? (
            <Spinner />
          ) : (
            <QuoteDataTable quoteData={data?.data} />
          )}
        </div>
      </Container>
    </section>
  );
}

export default Quote;
