import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../../core/app-constants";
import { BaseResponse } from "../../model/response/base-response";
import { PolicyResponse } from "../../model/response/policy.response";
import { PolicyRequest } from "../../model/request/policy.request";
import { PolicyType } from "../../model/response/policy-type.response";
import { ConvertQuoteRequest } from "../../model/request/convertQuote.request";
import { QuoteResponse } from "../../model/response/quote.response";




export const policyService = createApi({
    reducerPath: "policyService",
    baseQuery: fetchBaseQuery({
      baseUrl: `${AppConstants.baseUrl}`,
      // headers: { "Content-Type": "application/json; charset=UTF-8" },
    }),
    tagTypes: ["Policies"],
    endpoints: (builder) => ({
      getAllPolicies: builder.query<BaseResponse<PolicyResponse[]>, void>({
        query: () => ({
          url: `/policies?page=1&size=1000000000`,
          method: "GET",
        }),
        providesTags: ["Policies"],
      }),
      getPolicyById: builder.mutation<BaseResponse<PolicyResponse[]>, number>({
        query: (policyId) => ({
        url: `/policies/${policyId}`,
        method: "GET"
      }),
      invalidatesTags: ["Policies"],
    }),
      addPolicy: builder.mutation<BaseResponse<PolicyResponse[]>,PolicyRequest>({
        query: (policy) => ({
          url: "/policies",
          method: "POST",
          body: policy,
        }),
        invalidatesTags: ["Policies"],
      }),
      deletePolicy: builder.mutation<BaseResponse<PolicyResponse[]>, any>({
        query: (policyId) => ({
          url: `/policies/${policyId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Policies"],
    }),
    updatePolicyById: builder.mutation<BaseResponse<PolicyResponse[]>, PolicyRequest>({
      query: (policy) => ({
        url: `/policies`,
        method: "PUT",
        body: policy,
      }),
      invalidatesTags: ["Policies"],
  }),
  searchPolicy: builder.mutation<BaseResponse<PolicyResponse[]>, any>({
    query: (policyNumber) => ({
      url: `/policies?policyNumber=${policyNumber}`,
      method: "GET",
    }),
    invalidatesTags: ["Policies"],
}),
filterPolicy: builder.mutation<BaseResponse<PolicyResponse[]>, any>({
  query: (params) => ({
    url: `/policies`+ params,
    method: "GET",
  }),
  invalidatesTags: ["Policies"],
}),
policyTypesLsit: builder.query<BaseResponse<PolicyType[]>, void>({
  query: () => ({
    url: `/insuranceTypes`,
    method: "GET",
  }),
}),
convertQuotes: builder.mutation<BaseResponse<QuoteResponse[]>, ConvertQuoteRequest>({
  query: (convert) => ({
    url: `/policies/quote/conversion`,
    method: "POST",
    body: convert
  }),
}),
    }),
  });
  
export const {
useGetAllPoliciesQuery,
// useGetPolicyByIdQuery,
useFilterPolicyMutation,
useSearchPolicyMutation,
useGetPolicyByIdMutation,
useAddPolicyMutation,
useDeletePolicyMutation,
useUpdatePolicyByIdMutation,
usePolicyTypesLsitQuery,
useConvertQuotesMutation,
}= policyService;