import { Row, Col, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Logo from "../../../../../../assets/images/logo.png";
import { FC } from "react";
import { ClaimRequest } from "../../../../../../model/request/claim.request";
import AddClaimForm from "../forms/AddClaimsForm";
import { CustomerRequest } from "../../../../../../model/request/customer.request";

type AddClaimModalProps = {
  show: boolean;
  onHide: () => void;
  onAddClaim: (customer: ClaimRequest) => void;
  isEditMode?: boolean | false;
  selectedCustomer?: CustomerRequest;
  selectedClaim?: ClaimRequest;
};

const AddClaimModal: FC<AddClaimModalProps> = (props) => {
  const { show, onHide, isEditMode } = props;

  const handleAddFormData = (claim: ClaimRequest) => {
    props.onAddClaim(claim);
  };
  const buttonLabel = isEditMode ? "Update" : "Add";

  return (
    <div className="modal-container">
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
        <Modal.Header closeButton className="border-0 w-100"></Modal.Header>
        <Modal.Body className="modal-body-style p-5">
          <Row className="align-items-top">
            <Col>
              <Image src={Logo} width={150} />
            </Col>
            <Col md="auto">ADD CLAIM</Col>
          </Row>

          <div className="modal-form mt-5">
            <AddClaimForm
              onSubmitForm={handleAddFormData}
              closeModal={onHide}
              button={buttonLabel}
              claim={props.selectedClaim}
              isEditMode={isEditMode}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddClaimModal;
