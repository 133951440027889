import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../../core/app-constants";
import { BaseResponse } from "../../model/response/base-response";
import { Credentials } from "../../model/request/auth.request";
import { AuthUser } from "../../model/response/auth.response";

export const authService = createApi({
    reducerPath: "authService",
    baseQuery: fetchBaseQuery({
      baseUrl: `${AppConstants.baseUrl}`,
      // headers: { "Content-Type": "application/json; charset=UTF-8" },
    }),
    tagTypes: ["Users"],
    endpoints: (builder) => ({
      loginUser: builder.mutation<BaseResponse<AuthUser[]>, Credentials | any>({
        query: (userCredentials) => ({
          url: "/auth",
          method: "POST",
          body: userCredentials,
        }),
        invalidatesTags: ["Users"],
      }),
    }),
  });
  
export const {
useLoginUserMutation,
}= authService;
