import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { customerService } from "../services/customer/customer.service";
import { policyService } from "../services/policy/policy.service";
import { claimService } from "../services/claim/claim.service";
import { quoteService } from "../services/quote/quote.service";
import { commissionService } from "../services/commission/commission.service";
import { authService } from "../services/auth/auth.service";
import { paymentService } from "../services/payment/payment.service";

export const store = configureStore({
  reducer: {
    [customerService.reducerPath]: customerService.reducer,
    [policyService.reducerPath]: policyService.reducer,
    [claimService.reducerPath]: claimService.reducer,
    [quoteService.reducerPath]: quoteService.reducer,
    [commissionService.reducerPath]: commissionService.reducer,
    [authService.reducerPath]: authService.reducer,
    [paymentService.reducerPath]: paymentService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(customerService.middleware)
      .concat(policyService.middleware)
      .concat(claimService.middleware)
      .concat(quoteService.middleware)
      .concat(commissionService.middleware)
      .concat(authService.middleware)
      .concat(paymentService.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
