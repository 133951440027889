import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  FloatingLabel,
  Form,
  Row,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { ClaimRequest } from "../../../../../../model/request/claim.request";
import { useSearchCustomerMutation } from "../../../../../../services/customer/customer.service";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  useAddClaimMutation,
  useGetClaimByIdMutation,
  useUpdateClaimByIdMutation,
} from "../../../../../../services/claim/claim.service";
import { toast } from "react-toastify";
import { InsuranceCompanies } from "../../../../../shared/components/InsuranceCompanies";
import { PolicyType } from "../../../../../../model/response/policy-type.response";
import { usePolicyTypesLsitQuery } from "../../../../../../services/policy/policy.service";

type AddClaimFormProps = {
  onSubmitForm: (claim: ClaimRequest) => void;
  claim?: ClaimRequest | {};
  closeModal: () => void;
  isEditMode?: boolean | false;
  button: string | "";
};

const AddClaimForm: FC<AddClaimFormProps> = (props) => {
  const { claim, isEditMode, button } = props;
  const [loading, setLoading] = useState(false);
  const [addClaim] = useAddClaimMutation();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCustomer, { data }] = useSearchCustomerMutation();
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [getClaimById, { data: claimData }] = useGetClaimByIdMutation();

  const [policyType, setPolicyType] = useState("");
  const [updateClaimById] = useUpdateClaimByIdMutation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerIDNumber, setCustomerIDNumber] = useState("");
  const [placeholder, setPlaceholder] = useState("Ghana Card Number");
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>(
    {}
  );
  const { data: policyTypeData } = usePolicyTypesLsitQuery();
  const [showPolicyTypes, setShowPolicyTypes] = useState<PolicyType[]>();

  const handleInputChange = (
    text: any,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const customerName: string = e.target.value;
    setSearchQuery(customerName);
    if (customerName.trim() !== "") {
      searchCustomer(customerName).unwrap();
    }
  };

  const handleSelectCustomer = (customer: any) => {
    if (customer && customer.length > 0) {
      const selectedCustomer = customer[0];
      setCustomerId(selectedCustomer?.id);
      setSelectedCustomer(selectedCustomer);
      setSearchQuery(selectedCustomer?.name);

      if (selectedCustomer.customerType === "INDIVIDUAL") {
        setPhoneNumber(selectedCustomer.msisdn);
        setCustomerIDNumber(selectedCustomer.ghanaCardNumber);
        setPlaceholder("Ghana Card Number");
      } else {
        setPhoneNumber(selectedCustomer.msisdn);
        setCustomerIDNumber(selectedCustomer.certificateOfIncorporationNumber);
        setPlaceholder("Certificate of Incorporation Number");
      }
    }
  };

  const [formData, setFormData] = useState<ClaimRequest>(claim || {});

  const claimDetail = claimData?.data || [];

  useEffect(() => {
    if (claimDetail.length > 0) {
      setFormData(claimDetail[0] as ClaimRequest);
    }
  }, [claimDetail]);

  useEffect(() => {
    // Fetch claim data when the component mounts
    if (isEditMode && claim) {
      getClaimById(claim);
      setPolicyType(formData.policyType ?? "");
      // Adjust this line based on your useGetClaimByIdMutation API usage
    }
  }, []);

  useEffect(() => {
    setShowPolicyTypes(policyTypeData?.data);
    console.log("show", showPolicyTypes);
  }, [policyTypeData, showPolicyTypes]);

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (!formData) return;
    // setFormData({ ...formData, [e.target.name]: e.target.value });

    const { name, value } = e.target;
    if (name === "policyType") {
      setPolicyType(value);
    }

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      customerId: selectedCustomer?.id || "",
      employee: { id: "1", name: "yaw" },
    }));
  };

  const onSubmitForm = async (claim: ClaimRequest, e: React.FormEvent) => {
    e.preventDefault();

    const requiredFields: (keyof ClaimRequest)[] = [
      "clientClassification",
      "insuranceCompany",
      "dateOfRequest",
      "dateOfIncident",
      "currentStatus",
      "remarks",
      "policyType",
    ];

    const errors: { [key in keyof ClaimRequest]: boolean } = {} as {
      [key in keyof ClaimRequest]: boolean;
    };
    if (isEditMode) {
      requiredFields.forEach((field) => {
        if (!formData[field]) {
          errors[field] = true;
        }
      });
    } else {
      if (!customerId) {
        errors.customerId = true;
      }
      if (!formData.clientClassification) {
        errors.clientClassification = true;
      }
      if (!formData.insuranceCompany) {
        errors.insuranceCompany = true;
      }
      if (!formData.dateOfRequest) {
        errors.dateOfRequest = true;
      }
      if (!formData.policyType) {
        errors.policyType = true;
      }
      if (!formData.currentStatus) {
        errors.currentStatus = true;
      }
      if (!formData.dateOfIncident) {
        errors.dateOfIncident = true;
      }
      if (!formData.remarks) {
        errors.remarks = true;
      }
      if (!formData.policyType) {
        errors.policyType = true;
      }
    }

    // Set the field errors
    setFieldErrors(errors);

    if (Object.keys(errors).length === 0) {
      setFieldErrors({});
      setLoading(true);
      try {
        if (isEditMode) {
          let response = await updateClaimById(formData);
        } else {
          let response = await addClaim(formData);
        }
        toast("Claim added successfully");
        props.closeModal();
      } catch (e) {
        toast("Failed adding claim, please try again.");
      }
    }
  };

  return (
    <Form className="modal-forms" onSubmit={(e) => onSubmitForm(formData, e)}>
      {isEditMode === false ? (
        <>
          <Row>
            <InputGroup className="mb-3" as={Col}>
              <FloatingLabel label="">
                <Typeahead
                  id="searchCustomer"
                  labelKey="name"
                  options={data?.data || []}
                  placeholder="Customer Name"
                  size="lg"
                  onChange={handleSelectCustomer}
                  onInputChange={handleInputChange}
                  inputProps={{
                    className: "my-custom-classname rounded-0 p-3",
                    style: {
                      fontSize: "15px",
                    },
                  }}
                />
              </FloatingLabel>
            </InputGroup>
          </Row>
          <Row className="gx-2 pb-2">
            <Form.Group as={Col}>
              <FloatingLabel label="Phone Number">
                <Form.Control
                  type="text"
                  disabled={!selectedCustomer}
                  value={phoneNumber}
                  className="rounded-0 border-primary"
                  name="customerMsisdn"
                  onChange={handleFormChanged}
                  required
                  isInvalid={fieldErrors.customerId}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group as={Col}>
              <FloatingLabel label={placeholder}>
                <Form.Control
                  type="text"
                  disabled
                  value={customerIDNumber}
                  className="rounded-0 border-primary"
                  // name="insuranceCompany"
                  onChange={handleFormChanged}
                  required
                  isInvalid={fieldErrors.customerId}
                />
              </FloatingLabel>
            </Form.Group>
          </Row>
        </>
      ) : (
        <Row hidden={true}>
          <Form.Group as={Col} className="pb-2">
            <FloatingLabel label="Customer ID">
              <Form.Control
                type="text"
                disabled
                value={customerId}
                className="rounded-0 border-primary"
                name="customerId"
                onChange={handleFormChanged}
                required
                isInvalid={fieldErrors.customerId}
              />
            </FloatingLabel>
          </Form.Group>
        </Row>
      )}

      <Row className="gx-2">
        <Form.Group as={Col}>
          <FloatingLabel label="Policy Type">
            <Form.Control
              as="select"
              type="text"
              className="rounded-0 border-primary"
              name="policyType"
              onChange={handleFormChanged}
              value={formData.policyType || ""}
              required
              isInvalid={fieldErrors.policyType}
            >
              {showPolicyTypes &&
                showPolicyTypes?.map((policy, index) => (
                  <option key={index} value={policy.value}>
                    {" "}
                    {policy.displayName}{" "}
                  </option>
                ))}
            </Form.Control>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Client Classification">
            <Form.Control
              as="select"
              type="text"
              className="rounded-0 border-primary"
              name="clientClassification"
              onChange={handleFormChanged}
              value={formData.clientClassification}
              required
              isInvalid={fieldErrors.clientClassification}
            >
              <option value="" selected disabled>
                -- Select client Classification --
              </option>
              <option value="Retail">Retail</option>
              <option value="Corporate">Corporate</option>
            </Form.Control>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Currency Status">
            <Form.Control
              as="select"
              type="text"
              className="rounded-0 border-primary"
              name="currentStatus"
              onChange={handleFormChanged}
              value={formData.currentStatus || ""}
              required
              isInvalid={fieldErrors.currentStatus}
            >
              <option value="" selected disabled>
                -- Select Current Status --
              </option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Form.Control>
          </FloatingLabel>
        </Form.Group>
      </Row>

      <Row
        className="gx-2 mt-2"
        hidden={
          [
            "MOTOR_THIRD_PARTY",
            "MOTOR_COMPREHENSIVE",
            "MOTOR_THIRD_PARTY, FIRE & THEFT",
          ].includes(policyType) !== true
        }
      >
        <Form.Group as={Col}>
          <FloatingLabel label="Vehicle Number">
            <Form.Control
              type="text"
              className="rounded-0 border-primary"
              name="vehicleNumber"
              onChange={handleFormChanged}
              value={formData.vehicleNumber}
              // required
              isInvalid={fieldErrors.vehicleNumber}
            ></Form.Control>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Vehicle Make or Model">
            <Form.Control
              type="text"
              className="rounded-0 border-primary"
              name="vehicleMakeOrModel"
              onChange={handleFormChanged}
              value={formData.vehicleMakeOrModel}
              // required
              isInvalid={fieldErrors.vehicleMakeOrModel}
            ></Form.Control>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Number Of Vehicles/Property">
            <Form.Control
              type="text"
              className="rounded-0 border-primary"
              name="numberOfProperties"
              onChange={handleFormChanged}
              value={formData.numberOfProperties || ""}
              // required
              isInvalid={fieldErrors.numberOfProperties}
            />
          </FloatingLabel>
        </Form.Group>
      </Row>

      <Row className="gx-2 mt-2">
        <Form.Group as={Col}>
          <FloatingLabel label="Date of request">
            <Form.Control
              type="date"
              className="rounded-0 border-primary"
              name="dateOfRequest"
              onChange={handleFormChanged}
              value={formData.dateOfRequest || ""}
              required
              isInvalid={fieldErrors.dateOfRequest}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Date of Incident">
            <Form.Control
              type="date"
              className="rounded-0 border-primary"
              name="dateOfIncident"
              onChange={handleFormChanged}
              value={formData.dateOfIncident || ""}
              required
              isInvalid={fieldErrors.dateOfIncident}
            />
          </FloatingLabel>
        </Form.Group>
      </Row>

      <Row className="gx-2 mt-2">
        <Form.Group as={Col}>
          <FloatingLabel label="Insurance Company">
            <Form.Control
              as="select"
              type="text"
              className="rounded-0 border-primary"
              name="insuranceCompany"
              onChange={handleFormChanged}
              value={formData.insuranceCompany || ""}
              required
              isInvalid={fieldErrors.insuranceCompany}
            >
              <option value="" selected disabled>
                -- select insurance company --
              </option>
              {InsuranceCompanies.map((company, index) => (
                <option key={index}>{company}</option>
              ))}
            </Form.Control>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Remark">
            <Form.Control
              type="text"
              className="rounded-0 border-primary"
              name="remarks"
              onChange={handleFormChanged}
              value={formData.remarks || ""}
              required
              isInvalid={fieldErrors.remarks}
            />
          </FloatingLabel>
        </Form.Group>
      </Row>

      <Row className="mt-3">
        <Col className="text-end">
          <Button type="submit" size="lg" className="rounded-0 px-5">
            {loading ? (
              <Spinner
                style={{ fontSize: "2px", height: "20px", width: "20px" }}
              />
            ) : (
              <span>{button} Claim</span>
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddClaimForm;
