import {
  Col,
  FloatingLabel,
  Form,
  Row,
  Tab,
  Nav,
  Button,
  Image,
} from "react-bootstrap";
import File from "../../../../../../assets/images/file.gif";
import { PolicyRequest } from "../../../../../../model/request/policy.request";
import { FC, useState, ChangeEvent } from "react";
import { PaymentTypes } from "../../../../../shared/components/PaymentTypes";


type VehicleInfoFormProps = {
  activeTab: string;
  dataForForm: any;
  policy?: PolicyRequest | {};
  isEditMode?: boolean | false;
  nextTab: () => void;
  previousTab: () => void;
  onSubmitForm: (data: PolicyRequest) => void;
};

const VehicleInfoForm: FC<VehicleInfoFormProps> = (props) => {
  const [formData, setFormData] = useState(props.dataForForm);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>(
    {}
  );

  const data = props.dataForForm;
  console.log("sending money", data.policyType);

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Clear the specific error when the field is updated

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      type: "INTERNAL",
    });
  };

  const onSubmitForm = () => {
    const requiredFields: string[] = [
      "exchangeRate",
      "transactionCurrency",
      "sumInsured",
      "paymentTerms",
      "premiumPayable",
      "premiumPaid",
      "premiumPaymentChannel",
    ];

    const errors: { [key: string]: boolean } = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = true;
      }
    });

    // Set the field errors
    setFieldErrors(errors);

    // If there are no errors, proceed with form submission
    if (Object.keys(errors).length === 0) {
      // Reset the field errors
      setFieldErrors({});
      console.log(formData);
      props.onSubmitForm(formData);
      props.nextTab();
    }
  };

  const previousTab = () => {
    props.previousTab();
  };

  return (
    <Tab.Container defaultActiveKey="first">
      <Row>
        <Nav
          variant="pills"
          className="flex-row tabs-class w-100 d-flex px-5 text-center justify-content-end "
        >
          {/* <Nav.Item className=" nav-item-tabs mx-3 ">
            <Nav.Link eventKey="first" className="fw-semibold fs-6  px-5">
              ADD POLICY
            </Nav.Link>
          </Nav.Item> */}
          {/* <Nav.Item className=" nav-item-tabs">
            <Nav.Link eventKey="second" className="fw-semibold fs-6 px-5">
              ADD BULK POLICY
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
      </Row>

      <Tab.Content>
        <Tab.Pane eventKey="first">
          <Form className="modal-forms px-5 mt-3">
            <Row
              className="pb-2 gx-3"
              hidden={
                [
                  "MOTOR_THIRD_PARTY",
                  "MOTOR_COMPREHENSIVE",
                  "MOTOR_THIRD_PARTY, FIRE & THEFT",
                ].includes(formData.policyType) !== true
              }
            >
              <Form.Group as={Col}>
                <FloatingLabel label="Vehicle Registration Number">
                  <Form.Control
                    type="text"
                    name="vehicleRegistrationNumer"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData.vehicleNumber}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Vehicle make or model">
                  <Form.Control
                    type="text"
                    name="vehiclemode"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData.vehicleMakeOrModel}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="pb-2 gx-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Exchange Rate">
                  <Form.Control
                    type="text"
                    name="exchangeRate"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData.exchangeRate}
                    isInvalid={fieldErrors.exchangeRate}
                    required
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Transaction Currency">
                  <Form.Control
                    as="select"
                    type="text"
                    name="transactionCurrency"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData.transactionCurrency}
                    isInvalid={fieldErrors.transactionCurrency}
                    required
                  >
                    <option value="" selected disabled>-- Select Transaction Currency --</option>
                    <option value="USD">USD</option>
                    <option value="GHS">GHS</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="pb-2 gx-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Sum insured (Transaction Currency)">
                  <Form.Control
                    type="text"
                    name="sumInsured"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData.sumInsured}
                    isInvalid={fieldErrors.sumInsured}
                    required
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Payment Terms">
                  <Form.Control
                    as="select"
                    type="text"
                    name="paymentTerms"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData.paymentTerms}
                    isInvalid={fieldErrors.paymentTerms}
                    required
                  >
                    <option value="" selected disabled>-- Select Payment -Term-</option>
                   
                    {PaymentTypes.map((payments, index) => (
                      <option key={index}>{payments}</option>
                    ))}

                   
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="pb-2 gx-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Premium Payable (Transaction Currency)">
                  <Form.Control
                    type="text"
                    name="premiumPayable"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData.premiumPayable}
                    isInvalid={fieldErrors.premiumPayable}
                    required
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Premium Paid (Transaction Currency)">
                  <Form.Control
                    type="text"
                    name="premiumPaid"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData.premiumPaid}
                    isInvalid={fieldErrors.premiumPaid}
                    required
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="pb-2 gx-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Premium Payment Channel">
                  <Form.Control
                    as="select"
                    type="text"
                    name="premiumPaymentChannel"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData.premiumPaymentChannel}
                    isInvalid={fieldErrors.premiumPaymentChannel}
                    required
                  >
                    <option value="" selected disabled>
                      -- Select Premium Payment Channel --
                    </option>

                    <option value="USSD">USSD</option>
                    <option value="Direct">Direct</option>
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>
          </Form>
        </Tab.Pane>
        <Tab.Pane eventKey="second">
          <div className="text-center ">
            <Image alt="img" src={File} style={{ width: "35%" }} />
          </div>
          <div className="text-center">
            <a href="#/something">Download sample file</a>
          </div>
          <Form.Group as={Col} className="px-5">
            <Form.Control
              type="file"
              name="uploadFile"
              className="rounded-0 border-primary"
            />
          </Form.Group>
        </Tab.Pane>
        <div className="text-end px-5 mt-4">
          <Button
            variant="light"
            className="rounded-0 px-5 mx-2 "
            onClick={() => previousTab()}
          >
            Previous
          </Button>
          <Button className="rounded-0 px-5" onClick={() => onSubmitForm()}>
            Next
          </Button>
        </div>
      </Tab.Content>
    </Tab.Container>
  );
};

export default VehicleInfoForm;
