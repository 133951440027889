import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../../core/app-constants";
import { CommissionRequest } from "../../model/request/commission.request";
import { CommissionResponse } from "../../model/response/commission.response";
import { BaseResponse } from "../../model/response/base-response";


export const commissionService = createApi({
  reducerPath: "commissionService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.baseUrl}`,
  }),
  tagTypes: ["Commissions"],
  endpoints: (builder) => ({
    getAllCommission: builder.query<BaseResponse<CommissionResponse[]>, void>({
      query: () => ({
        url: "/policy/periods?page=1&size=1000000000",
        method: "GET",
      }),
      providesTags: ["Commissions"],
    }),
    getCommissionById: builder.mutation<BaseResponse<CommissionResponse[]>, any>({
      query: (commissionId) => ({
        url: `/policy/periods/${commissionId}`,
        method: "GET",
      }),
      invalidatesTags: ["Commissions"],
    }),
    updateCommissionById: builder.mutation<BaseResponse<CommissionRequest[]>, CommissionRequest>({
      query: (commission) => ({
        url: `/policy/periods/commissions`,
        method: "POST",
        body:commission,
      }),
      invalidatesTags: ["Commissions"],
    }),
    deleteCommissionById: builder.mutation<BaseResponse<CommissionRequest[]>, any>({
      query: (commissionId) => ({
        url: `/policy/periods/${commissionId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Commissions"],
    }),
    searchCommission: builder.mutation<BaseResponse<CommissionResponse[]>, any>({
      query: (policyId) => ({
        url: `/policy/periods?policyId=${policyId}`,
        method: "GET",
      }),
      invalidatesTags: ["Commissions"],
  }),
    filterCommission: builder.mutation<BaseResponse<CommissionResponse[]>, any>({
      query: (params) => ({
        url: `/policy/periods`+ params,
        method: "GET",
      }),
      invalidatesTags: ["Commissions"],
    }),
    
    // getCustomerById: builder.query<BaseResponse<CommisionResponse>, string>({
    //   query: (customerId) => `/customers/${customerId}`,
    // }),
  }),
});

export const {
    useGetAllCommissionQuery,
    useGetCommissionByIdMutation,
    useFilterCommissionMutation,
    useSearchCommissionMutation,
    useUpdateCommissionByIdMutation,
    useDeleteCommissionByIdMutation,
} = commissionService;