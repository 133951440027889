import { FC } from "react";
import { Row, Col, Button } from "react-bootstrap";

type PageTitleProps = {
  title: string;
  hasCTA: boolean;
  ctaLabel?: string;
  ctaIcon?: any;
  ctaAction?(): void;
};

const PageTitle: FC<PageTitleProps> = (props) => {
  return (
    <Row>
      <Col>
        <h6 className="text-white fs-4 fw-semibold">{props.title}</h6>
      </Col>
      {props.hasCTA && (
        <Col className="text-end">
          <Button variant="light" size="sm" className="px-5 py-2 rounded-0" onClick={props.ctaAction}>
            {props.ctaIcon} {props.ctaLabel}
          </Button>
        </Col>
      )}
    </Row>
  );
};
export default PageTitle;
