import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { PolicyRequest } from "../../../../../model/request/policy.request";
import {
  useAddPolicyMutation,
  useGetAllPoliciesQuery,
} from "../../../../../services/policy/policy.service";
import AddPolicyModal from "../../policy/components/modals/AddPolicyModal";

function PolicyCard() {
  const { data } = useGetAllPoliciesQuery();
  const [policyCount, setPolicyCount] = useState(0);
  const [createPolicyModal, setCreatePolicyModal] = useState(false);
  const [addPolicy] = useAddPolicyMutation();

  useEffect(() => {
    const policyCount = data?.data.length;
    setPolicyCount(policyCount ?? 0);

  }, [data]);

  const handleAddPolicy = async (policy: PolicyRequest) => {
    try {

      await addPolicy(policy);
      toast("Policy added successfully");
      setCreatePolicyModal(false);
    } catch (e) {
      toast("Failed adding policy, please try again.");
    }
  };

  return (
    <div className="shadow-lg p-4">
      <Card className="policy-card rounded-0 ">
        <div className="text-center">
          <div className="d-inline align-items-center">
            <div className="policy-count">
              <span className=" fw-semibold text-white">{policyCount}</span>
            </div>
            <div className="fs-3 mb-4  fw-semibold text-white">Current Policies</div>
          
            <div>
              <Button
                onClick={() => setCreatePolicyModal(true)}
                className="bg-white policy-card-btn text-primary w-75 shadow-lg border-0 rounded-0 py-3 "
              >
                + Add New Policy
              </Button>
            </div>
          </div>
        </div>
      </Card>
      <AddPolicyModal
        show={createPolicyModal}
        onHide={() => setCreatePolicyModal(false)}
        onAddPolicy={handleAddPolicy}
        isEditMode={false}
      />
    </div>
  );
}

export default PolicyCard;
