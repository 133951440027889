import { Row, Nav, Tab, Image, Modal } from "react-bootstrap";
import Logo from "../../../../../../assets/images/logo.png";
import { FC, useState } from "react";
import PolicyInfoForm from "../forms/PolicyInfoForm";
import VehicleInfoForm from "../forms/VehicleInfoForm";
import ResidualInfoForm from "../forms/ResidualInfoForm";
import { PolicyRequest } from "../../../../../../model/request/policy.request";
import { CustomerRequest } from "../../../../../../model/request/customer.request";
import { PolicyResponse } from "../../../../../../model/response/policy.response";

type AddPolicyModalProps = {
  show: boolean;
  onHide: () => void;
  onAddPolicy: (policy: PolicyRequest) => void;
  isEditMode: boolean | false;
  selectedCustomer?: CustomerRequest;
  selectedPolicy?: PolicyRequest;
};

const AddPolicyModal: FC<AddPolicyModalProps> = (props) => {
  const {selectedPolicy } = props;
  const [activeTab, setActiveTab] = useState<string>("first");
  const [formData, setFormData] = useState<PolicyRequest>(selectedPolicy || {});

  const handleNextTab = () => {
    if (activeTab !== "second") {
      setActiveTab("second");
    } else {
      setActiveTab("third");
    }
  };

  const handlePreviousTab = () => {
    if (activeTab !== "first") {
      if (activeTab === "third") {
        setActiveTab("second");
      } else if (activeTab === "second") {
        setActiveTab("first");
      }
    }
  };
  const buttonLabel = props.isEditMode ? "Update" : "Add";

  const handleCombinedData = (formFields: PolicyRequest) => {
    // props.onPolicyCustomer(policy);
    if (formData.policyType === "policyType1") {
    }
    let updated = Object.assign(formData, formFields);
    if (activeTab === "third") {
      props.onAddPolicy(formData);
      props.onHide();
    }
  };

  return (
    <div className="modal-container">
      <Modal
        {...props}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
        <Modal.Header closeButton className="border-0 w-100"></Modal.Header>
        <Modal.Body className="modal-body-style">
          <div className="d-flex justify-content-between px-5 align-items-center header-with-logo-and-modal-title">
            <div>
              <Image src={Logo} width={150} />
            </div>
            <div className="text-end">
              <span className="text-primary fs-6 fw-semibold">ADD POLICY</span>
            </div>
          </div>
          <div className="modal-form mt-3">
            <Tab.Container
              id="tab-container"
              activeKey={activeTab}
              defaultActiveKey="first"
            >
              <Row className="tabs-style">
                <Nav
                  variant="pills"
                  className="flex-row tabs-class w-100 d-flex text-center justify-content-center "
                >
                  <Nav.Item className="w-25 nav-item-tabs">
                    <Nav.Link eventKey="first" className="fw-semibold fs-6">
                      POLICY INFO
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="w-25 nav-item-tabs">
                    <Nav.Link eventKey="second" className="fw-semibold fs-6">
                      ASSET INFO
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="w-25 nav-item-tabs">
                    <Nav.Link eventKey="third" className="fw-semibold fs-6">
                      RESIDUAL INFO
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Row>
              <Row className="mt-4">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <PolicyInfoForm
                      onSubmitForm={handleCombinedData}
                      activeTab={activeTab}
                      nextTab={handleNextTab}
                      isEditMode={props.isEditMode}
                      policy={props.selectedPolicy}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <VehicleInfoForm
                      onSubmitForm={handleCombinedData}
                      activeTab={activeTab}
                      nextTab={handleNextTab}
                      dataForForm={formData}
                      previousTab={handlePreviousTab}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <ResidualInfoForm
                      onSubmitForm={handleCombinedData}
                      onHide={props.onHide}
                      previousTab={handlePreviousTab}
                      dataForForm={formData}
                      button={buttonLabel}
                      isEditMode={props.isEditMode}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Row>
            </Tab.Container>
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
    </div>
  );
};

export default AddPolicyModal;
