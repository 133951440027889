import { ChangeEvent } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Image,
  Modal,
  FloatingLabel,
} from "react-bootstrap";
import Logo from "../../../../../../assets/images/logo.png";
import { InsuranceCompanies } from "../../../../../shared/components/InsuranceCompanies";

interface ModalType {
  show: boolean;
  onHide: () => void;
  filterCriteria: any;
  setFilterCriteria: any;
  handleFilter: () => void;
}

function FilterAndExportCommission(props: ModalType) {
  const { setFilterCriteria, filterCriteria } = props;

  const handleApplyFilter = () => {
    // Update the filterCriteria state with the generated queryString
    props.handleFilter();
  };

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterCriteria({
      ...filterCriteria,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="modal-container">
      <Modal
        {...props}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
        <Modal.Header closeButton className="border-0 w-100"></Modal.Header>
        <Modal.Body className="modal-body-style">
          <div className="d-flex justify-content-between px-5 align-items-center header-with-logo-and-modal-title">
            <div>
              <Image src={Logo} width={150} />
            </div>
            <div className="text-end">
              <span className="text-primary fs-5 fw-semibold">
                Filter and Export Commmissions{" "}
              </span>
            </div>
          </div>
          <div className="modal-form mt-3">
            <Form className="modal-forms mt-5">
              <Row className="px-5 mb-3">
                <Form.Group as={Col}>
                  <FloatingLabel label="Policy Status">
                    <Form.Control
                      as="select"
                      type="text"
                      name="policyStatus"
                      className="rounded-0 border-primary"
                      onChange={handleFormChanged}
                      value={filterCriteria.policyStatus}
                    >
                      <option value="" selected disabled> -- Select Policy Status--</option>
                      <option value="active"> Active </option>
                      <option value="inactive"> Inactive </option>
                    </Form.Control>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col}>
                  <FloatingLabel label="Insurance Company">
                    <Form.Control
                      as="select"
                      type="text"
                      name="insuranceCompany"
                      className="rounded-0 border-primary"
                      onChange={handleFormChanged}
                      value={filterCriteria.insuranceCompany}
                    >
                      {InsuranceCompanies.map((company, index) => (
                        <option key={index}> {company} </option>
                      ))}
                    </Form.Control>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="px-5">
                <Form.Group as={Col}>
                  <FloatingLabel label="Commission Type">
                    <Form.Control
                      as="select"
                      type="text"
                      name="commissionType"
                      className="rounded-0 border-primary"
                      onChange={handleFormChanged}
                      value={filterCriteria.commissionType}
                    >
                      <option value="" selected disabled> -- Select Commission Type --</option>
                      <option value="Bonus"> Bonus </option>
                      <option value="Overrider"> Overrider </option>
                      <option value="Regular"> Regular </option>
                    </Form.Control>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <div className="text-end px-5 mt-5">
                <Button
                  className="rounded-0 px-5"
                  onClick={() => handleApplyFilter()}
                >
                  Filter
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
    </div>
  );
}

export default FilterAndExportCommission;
