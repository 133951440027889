import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useEffect, useState } from "react";
import { useGetAllQuotesQuery } from "../../../../../services/quote/quote.service";
import { useGetAllPaymentsQuery } from "../../../../../services/payment/payment.service";
import { Card } from "react-bootstrap";

export const StatsCard = () => {
  const [progressValue, setProgressValue] = useState(0);
  const [pendingValue, setPendingValue] = useState(0);
  const [failedValue, setFailedValue] = useState(0);
  const [quote, setQuote] = useState(0);
  const [payment, setPayment] = useState(0);
  const [pending, setPending] = useState(0);
  const [failed, setFailed] = useState(0);
  const { data: totalQuotes } = useGetAllQuotesQuery();
  const { data: totalPayment } = useGetAllPaymentsQuery();

  useEffect(() => {
    const Quotes = totalQuotes?.data.length;
    setQuote(Quotes ?? 0);

    const PaymentSuccess = totalPayment?.data.filter(
      (item) => item.statusDescription == "SUCCESS"
    );
    const PaymentPending = totalPayment?.data.filter(
      (item) => item.statusDescription == "PENDING"
    );
    const PaymentFailed = totalPayment?.data.filter(
      (item) => item.statusDescription == "FAILED"
    );

    const FilteredPayment = PaymentSuccess?.length;
    const FilteredPaymentPending = PaymentPending?.length;
    const FilteredPaymentFailed = PaymentFailed?.length;

    setFailed(FilteredPaymentFailed ?? 0);
    setPending(FilteredPaymentPending ?? 0);
    setPayment(FilteredPayment ?? 0);
  }, [totalPayment, totalQuotes]);

  useEffect(() => {
    updateProgress();
    showPending();
    showFailed();
  }, [quote, payment]);
  useEffect(() => {
    showPending();
  }, [quote, payment]);
  useEffect(() => {
    showFailed();
  }, [quote, payment]);

  const updateProgress = () => {
    if (quote === 0) {
      setProgressValue(0);
    } else {
      const progress = (payment / quote) * 100;
      const calculatedValue = Math.round(progress);
      setProgressValue(calculatedValue);
    }
  };
  const showPending = () => {
    if (quote === 0) {
      setPendingValue(0);
    } else {
      const progress = (pending / quote) * 100;
      const calculatedValue = Math.round(progress);
      setPendingValue(calculatedValue);
    }
  };
  const showFailed = () => {
    if (quote === 0) {
      setFailedValue(0);
    } else {
      const progress = (failed / quote) * 100;
      const calculatedValue = Math.round(progress);
      setFailedValue(calculatedValue);
    }
  };

  const statsData = [
    {
      count: `${payment}`,
      quote: `${quote}`,
      value: progressValue,
      title: "Paid"
    },
    {
        count: `${pending}`,
      quote: `${quote}`,
      value: pendingValue,
      title: "Pending"
    },
    {
        count: `${failed}`,
      quote: `${quote}`,
      value: failedValue,
      title: "Failed"
    },
  ];

  return (
    <Card className=" border-0 bg-transparent border-0">
      <div className="">
        {statsData.map((data, item) => (
          <div key={item} className="shadow-lg mb-4 p-3">
            <div className="w-100 d-flex justify-content-between mx-3">
              <div className="fw-bold text-muted ">
                {data.title} :
                <span className="text-primary fw-bold mx-2 ">{data.count}</span>{" "}
              </div>
              <div className="fw-bold text-muted px-5">
                Quotes :
                <span className="text-primary fw-bold">{data.quote}</span>{" "}
              </div>
            </div>
            <ProgressBar
              now={data.value}
              variant="secondary"
              label={`${data.value}%`}
              className="mb-3 mx-3"
              style={{height:"10px"}}
            />
          </div>
        ))}
      </div>
    </Card>
  );
};
