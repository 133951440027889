import React, { useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { PageTitle } from "../../../shared/components";
import { BiPlus } from "react-icons/bi";
import CustomerTable from "./components/CustomerTable";
import AddCustomerModal from "./components/modals/AddCustomerModal";
import {
  useAddCustomerMutation,
  useGetAllCustomersQuery,
} from "../../../../services/customer/customer.service";
import { CustomerRequest } from "../../../../model/request/customer.request";
import { toast } from "react-toastify";

const Customers = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const { isLoading, data } = useGetAllCustomersQuery();
  const [addCustomer] = useAddCustomerMutation();

  const handleAddCustomer = async (customer: CustomerRequest) => {
    try {
       await addCustomer(customer);
      toast("Customer added succesfully");
    } catch (e) {
      console.log("Error: ", e);
      toast("Failed adding customer, please try again.");
    }
  };

  return (
    <section className="main-page">
      <Container fluid className="px-5 pt-5">
        <PageTitle
          title="Customers"
          hasCTA={true}
          ctaLabel="Add Customer"
          ctaIcon={<BiPlus />}
          ctaAction={() => setModalShow(true)}
        />
        <div className="glass-bg-datable mt-2">
          {isLoading ? (
            <Spinner />
          ) : (
            <CustomerTable customerData={data?.data} />
          )}
        </div>
        <AddCustomerModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onAddCustomer={handleAddCustomer}
          isEditMode={false}
        />
      </Container>
    </section>
  );
};

export default Customers;
