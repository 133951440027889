import { FC, useState, ChangeEvent, useEffect } from "react";
import {
  Col,
  FloatingLabel,
  Form,
  Row,
  Button,
  InputGroup,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  useAddCustomerMutation,
  useSearchCustomerMutation,
} from "../../../../../../services/customer/customer.service";
import { CustomerRequest } from "../../../../../../model/request/customer.request";
import { toast } from "react-toastify";
import AddCustomerModal from "../../../customer/components/modals/AddCustomerModal";
import { PolicyRequest } from "../../../../../../model/request/policy.request";
import { InsuranceCompanies } from "../../../../../shared/components/InsuranceCompanies";
import { usePolicyTypesLsitQuery } from "../../../../../../services/policy/policy.service";
import { PolicyType } from "../../../../../../model/response/policy-type.response";

type PolicyInfoFormProps = {
  activeTab: string;
  nextTab: () => void;
  policy?: PolicyRequest;
  isEditMode: boolean | false;
  onSubmitForm: (data: PolicyRequest) => void;
};

const PolicyInfoForm: FC<PolicyInfoFormProps> = (props) => {
  const { policy, isEditMode } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCustomer, { data }] = useSearchCustomerMutation();
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerIDNumber, setCustomerIDNumber] = useState("");
  const [placeholder, setPlaceholder] = useState("Ghana Card Number");
  const [formData, setFormData] = useState<PolicyRequest>(policy || {});
  const { data: policyTypeData } = usePolicyTypesLsitQuery();
  const [showPolicyTypes, setShowPolicyTypes] = useState<PolicyType[]>();
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    setShowPolicyTypes(policyTypeData?.data);
    // console.log("show", showPolicyTypes);
  }, [policyTypeData, showPolicyTypes]);

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Clear the specific error when the field is updated
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      customerId: selectedCustomer?.id || "",
    }));
  };

  const onSubmitForm = () => {
    const requiredFields: (keyof PolicyRequest)[] = [
      "policyNumber",
      "clientClassification",
      "insuranceCompany",
      "dateOfRequest",
      "transactionType",
      "policyType",
    ];

    const errors: { [key in keyof PolicyRequest]: boolean } = {};

    if (isEditMode) {
      requiredFields.forEach((field) => {
        if (!formData[field]) {
          errors[field] = true;
        }
      });
    } else {
      // Validate required fields in add mode
      if (!phoneNumber) {
        errors.customerMsisdn = true;
      }
      if (!formData.policyNumber) {
        errors.policyNumber = true;
      }
      if (!formData.clientClassification) {
        errors.clientClassification = true;
      }
      if (!formData.insuranceCompany) {
        errors.insuranceCompany = true;
      }
      if (!formData.dateOfRequest) {
        errors.dateOfRequest = true;
      }
      if (!formData.transactionType) {
        errors.transactionType = true;
      }
      if (!formData.policyType) {
        errors.policyType = true;
      }
    }
    // Set the field errors
    setFieldErrors(errors);
    // If there are no errors, proceed with form submission
    if (Object.keys(errors).length === 0) {
      // Reset the field errors
      setFieldErrors({});
      props.onSubmitForm(formData);
      props.nextTab();
    }
  };

  // ********** Below code for the customer search logics ***************//
  const handleInputChange = (
    text: any,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const customerName: string = e.target.value;
    setSearchQuery(customerName);
    if (customerName.trim() !== "") {
      searchCustomer(customerName).unwrap();
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const [addCustomer] = useAddCustomerMutation();

  const handleAddCustomer = async (customer: CustomerRequest) => {
    try {
      let response = await addCustomer(customer);
      // if(response)
      toast("Customer added successfully");
      setModalShow(false);
    } catch (e) {
      toast("Failed adding customer, please try again.");
    }
  };

  // ********** End ***************//

  // ********** Below code for the customer fill field logic ***************//

  const handleSelectCustomer = (customer: any) => {
    if (customer && customer.length > 0) {
      const selectedCustomer = customer[0];
      setCustomerId(selectedCustomer?.id);
      setSelectedCustomer(selectedCustomer);
      setSearchQuery(selectedCustomer?.name);

      if (selectedCustomer.customerType === "INDIVIDUAL") {
        setPhoneNumber(selectedCustomer.msisdn);
        setCustomerIDNumber(selectedCustomer.ghanaCardNumber);
        setPlaceholder("Ghana Card Number");
      } else {
        setPhoneNumber(selectedCustomer.msisdn);
        setCustomerIDNumber(selectedCustomer.certificateOfIncorporationNumber);
        setPlaceholder("Certificate of Incorporation Number");
      }
    }
  };

  return (
    <Form className="modal-forms px-5">
      {isEditMode === false ? (
        <div>
          <Form className="modal-forms ">
            <Row>
              <InputGroup className="mb-3" as={Col}>
                <FloatingLabel label="">
                  <Typeahead
                    id="searchCustomer"
                    labelKey="name"
                    options={data?.data || []}
                    placeholder="Customer Name"
                    size="lg"
                    onChange={handleSelectCustomer}
                    onInputChange={handleInputChange}
                    inputProps={{
                      className: "my-custom-classname rounded-0 p-3",
                      style: {
                        fontSize: "15px",
                      },
                    }}
                  />
                </FloatingLabel>
                <Button
                  variant="primary"
                  className="rounded-0"
                  onClick={() => setModalShow(true)}
                >
                  +
                </Button>
              </InputGroup>
            </Row>
          </Form>

          <Row className="pb-2 gx-3">
            <Form.Group as={Col}>
              <FloatingLabel label="Phone Number">
                <Form.Control
                  type="text"
                  className="rounded-0 border-primary"
                  name="customerMsisdn"
                  value={phoneNumber}
                  disabled={!selectedCustomer}
                  onChange={handleFormChanged}
                  isInvalid={fieldErrors.customerMsisdn}
                />
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col}>
              <FloatingLabel label={placeholder}>
                <Form.Control
                  type="text"
                  className="rounded-0 border-primary"
                  name="ghanaCardNumber"
                  value={customerIDNumber}
                  disabled={!selectedCustomer}
                  onChange={handleFormChanged}
                  isInvalid={fieldErrors.customerIDNumber}
                  required
                />
              </FloatingLabel>
            </Form.Group>
          </Row>
        </div>
      ) : (
        <></>
      )}

      <Row className="pb-2 gx-3">
        <Form.Group as={Col}>
          <FloatingLabel label="Policy Number">
            <Form.Control
              type="text"
              className="rounded-0 border-primary"
              name="policyNumber"
              value={formData.policyNumber}
              onChange={handleFormChanged}
              required
              isInvalid={fieldErrors.policyNumber}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Insurance Company">
            <Form.Control
              as="select"
              type="text"
              name="insuranceCompany"
              className="rounded-0 border-primary"
              onChange={handleFormChanged}
              value={formData.insuranceCompany}
              required
              isInvalid={fieldErrors.insuranceCompany}
            >
              <option value="" selected disabled>
                -- select insurance company --
              </option> 
              {InsuranceCompanies.map((company, index) => (
                <option key={index}>{company}</option>
              ))}
            </Form.Control>
          </FloatingLabel>
        </Form.Group>
      </Row>

      <Row className="gx-3 pb-2">
        <Form.Group as={Col}>
          <FloatingLabel label="Date of Request">
            <Form.Control
              type="date"
              name="dateOfRequest"
              className="rounded-0 border-primary"
              onChange={handleFormChanged}
              value={formData.dateOfRequest}
              isInvalid={fieldErrors.dateOfRequest}
              required
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Transaction Type">
            <Form.Control
              as="select"
              type="text"
              className="rounded-0 border-primary"
              name="transactionType"
              onChange={handleFormChanged}
              value={formData.transactionType}
              isInvalid={fieldErrors.transactionType}
              required
            >
              <option value="" selected disabled> -- Select Transaction Type --</option>
              <option value="New Business">New Business</option>
              <option value="Renewal">Renewal</option>
            </Form.Control>
          </FloatingLabel>
        </Form.Group>
      </Row>

      <Row className="gx-3 pb-2">
        <Form.Group as={Col}>
          <FloatingLabel label="Policy Type">
            <Form.Control
              as="select"
              type="text"
              name="policyType"
              className="rounded-0 border-primary"
              onChange={handleFormChanged}
              required
              value={formData.policyType}
              isInvalid={fieldErrors.policyType}
            >
              {showPolicyTypes &&
                showPolicyTypes?.map((policy, index) => (
                  <option key={index} value={policy.value}>
                    {" "}
                    {policy.displayName}{" "}
                  </option>
                ))}
            </Form.Control>
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Client Classification">
            <Form.Control
              as="select"
              type="text"
              className="rounded-0 border-primary"
              name="clientClassification"
              onChange={handleFormChanged}
              value={formData.clientClassification}
              isInvalid={fieldErrors.clientClassification}
              required
            >
              <option value="" selected disabled> -- select Client Classification -- </option>
              <option value="Retail"> Retail</option>
              <option value="Corporate">Corporate </option>
              <option value="Digital">Digital </option>
            </Form.Control>
          </FloatingLabel>
        </Form.Group>
      </Row>

      <div className="text-end">
        <Button className="rounded-0 px-5" onClick={(e) => onSubmitForm()}>
          Next
        </Button>
      </div>

      <AddCustomerModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onAddCustomer={handleAddCustomer}
      />
    </Form>
  );
};

export default PolicyInfoForm;
