import {FC} from "react";
import {
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import Logo from "../../../../../../assets/images/logo.png";
import { PolicyRequest } from "../../../../../../model/request/policy.request";

type ViewCustomerPoliciesProps = {
  show: boolean;
  onHide: () => void;
  customerPolicies?: PolicyRequest[];

  // other props for your component
};

const ViewCustomerPolicies: FC<ViewCustomerPoliciesProps> = (props) => {
  const { customerPolicies } = props;
 
  const exportFilteredData = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(customerPolicies);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "filtered_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data: any) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((item: any) => Object.values(item).join(","));
    return [header, ...rows].join("\n");
  };

  return (
    <div className="modal-container">
      <Modal
        {...props}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
        <Modal.Header closeButton className="border-0 w-100 text-primary">
          <div className="d-inline justify-content-between  align-items-center header-with-logo-and-modal-title">
            <div>
              <Image alt="img" src={Logo} width={150} />
            </div>
            <div className="mt-2">
              <span className="text-primary fs-5 px-3  fw-semibold">
                Customer's Policies{" "}
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body
          className="modal-body-style px-4 fw-bold text-muted"
          style={{ height: "500px", overflowY: "scroll" }}
        >
          {customerPolicies && customerPolicies.length !== 0 ? (
            <>
              {customerPolicies.map((data, key) => (
                <div
                  className="policy card mb-5 px-5 py-5 rounded-0 border-0 shadow-lg"
                  key={key}
                >
                  <div className="border-bottom mb-2">
                    Policy Number:{" "}
                    <span className="text-primary">{data.policyNumber}</span>{" "}
                  </div>
                  <div className="border-bottom mb-2">
                    Customer ID:{" "}
                    <span className="text-primary">{data.customerId}</span>
                  </div>
                  <div className="border-bottom mb-2">
                    Insurance Company:{" "}
                    <span className="text-primary">
                      {data.insuranceCompany}
                    </span>
                  </div>
                  <div className="border-bottom mb-2">
                    Transaction Type:{" "}
                    <span className="text-primary">{data.transactionType}</span>
                  </div>
                  <div className="border-bottom mb-2">
                    Sum Insured:{" "}
                    <span className="text-primary">{data.sumInsured}</span>
                  </div>
                  <div className="border-bottom mb-2">
                    Premium Payable:
                    <span className="text-primary">{data.premiumPayable}</span>
                  </div>
                  <div className="border-bottom mb-2">
                    Exchange Rate:{" "}
                    <span className="text-primary">{data.exchangeRate}</span>
                  </div>
                  <div className="border-bottom mb-2">
                    Premium Paid:{" "}
                    <span className="text-primary">{data.premiumPaid}</span>
                  </div>
                  <div className="border-bottom mb-2">
                    Payment Terms:{" "}
                    <span className="text-primary">{data.paymentTerms}</span>
                  </div>
                  <div className="border-bottom mb-2">
                    Premium Payment Channel:{" "}
                    <span className="text-primary">
                      {data.premiumPaymentChannel}
                    </span>
                  </div>
                  <div className="border-bottom mb-2">
                    Date of Request:{" "}
                    <span className="text-primary">{data.dateOfRequest}</span>
                  </div>
                  <div className="border-bottom mb-2">
                    Date Created:{" "}
                    <span className="text-primary">{data.createdDate}</span>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="text-center  ">
              <span>No policies found</span>
            </div>
          )}
        </Modal.Body>
        {customerPolicies && customerPolicies.length !== 0 ? (
          <Modal.Footer>
            <Button
              variant="primary"
              className="rounded-0"
              onClick={() => exportFilteredData()}
            >
              Export Customer's Policies
            </Button>
          </Modal.Footer>
        ) : (
          ""
        )}
      </Modal>
      {/* )} */}
    </div>
  );
};

export default ViewCustomerPolicies;
