import React from "react";
import { Container, Spinner } from "react-bootstrap";
import { PageTitle } from "../../../shared/components";
import { BiPlus } from "react-icons/bi";
import PolicyTable from "./components/PolicyTable";
import AddPolicyModal from "./components/modals/AddPolicyModal";
import {
  useAddPolicyMutation,
  useGetAllPoliciesQuery,
} from "../../../../services/policy/policy.service";
import { toast } from "react-toastify";
import { PolicyRequest } from "../../../../model/request/policy.request";

const Policies = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const { isLoading, data } = useGetAllPoliciesQuery();
  const [addPolicy] = useAddPolicyMutation();

  const handleAddPolicy = async (policy: PolicyRequest) => {
    try {
      await addPolicy(policy);
      toast("Policy added successfully");
      setModalShow(false);
    } catch (e) {
      toast("Failed adding policy, please try again.");
    }
  };

  return (
    <section className="main-page">
      <Container fluid className="px-5 pt-5">
        <PageTitle
          title="Policies"
          hasCTA={true}
          ctaLabel="Add Policy"
          ctaIcon={<BiPlus />}
          ctaAction={() => setModalShow(true)}
        />
        <div className="glass-bg-datable mt-2">
          {isLoading ? <Spinner /> : <PolicyTable policyData={data?.data} />}
        </div>
        <AddPolicyModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onAddPolicy={handleAddPolicy}
          isEditMode={false}
        />
      </Container>
    </section>
  );
};

export default Policies;
