import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../../core/app-constants";
import { CustomerRequest } from "../../model/request/customer.request";
import { CustomerResponse } from "../../model/response/customer.response";
import { BaseResponse } from "../../model/response/base-response";

export const customerService = createApi({
  reducerPath: "customerService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.baseUrl}`,
  }),
  tagTypes: ["Customers"],
  endpoints: (builder) => ({
    getAllCustomers: builder.query<BaseResponse<CustomerResponse[]>, void>({
      query: () => ({
        url: "/customers?page=1&size=1000000000",
        method: "GET",
      }),
      providesTags: ["Customers"],
    }),
    getCustomerById: builder.mutation<BaseResponse<CustomerResponse[]>, any>({
      query: (customerId) => ({
        url: `/customers/${customerId}`,
        method: "GET",
      }),
      invalidatesTags: ["Customers"],
    }),
    addCustomer: builder.mutation< BaseResponse<CustomerResponse[]>,CustomerRequest>({
      query: (customer) => ({
        url: "/customers",
        method: "POST",
        body: customer,
        headers: { "Content-Type": "application/json" },
      }),
      invalidatesTags: ["Customers"],
    }),
    searchCustomer: builder.mutation<BaseResponse<CustomerResponse[]>, any>({
      query: (name) => ({
        url: `/customers?name=${name}`,
        method: "GET",
      }),
      invalidatesTags: ["Customers"],
  }),
  deleteCustomer: builder.mutation<BaseResponse<CustomerResponse[]>, any>({
    query: (customerId) => ({
      url: `/customers/${customerId}`,
      method: "DELETE",
    }),
    invalidatesTags: ["Customers"],
}),
updateCustomer: builder.mutation<BaseResponse<CustomerResponse[]>, CustomerRequest>({
  query: (customer) => ({
    url: `/customers`,
    method: "PUT",
    body: customer,
  }),
  invalidatesTags: ["Customers"],
}),
filterCustomer: builder.mutation<BaseResponse<CustomerResponse[]>, any>({
  query: (params) => ({
    url: `/customers`+ params,
    method: "GET",
  }),
  invalidatesTags: ["Customers"],
}),
bulkCustomer: builder.mutation<BaseResponse<CustomerResponse[]>, CustomerRequest[]>({
  query: (customer) => ({
    url: `/customers/bulk/creation`,
    method: "POST",
    body: customer,
  }),
  invalidatesTags: ["Customers"],
}),
  }),
});

export const {
  useGetAllCustomersQuery,
  useGetCustomerByIdMutation,
  useAddCustomerMutation,
  useBulkCustomerMutation,
  useFilterCustomerMutation,
  useSearchCustomerMutation,
  useDeleteCustomerMutation,
  useUpdateCustomerMutation,
} = customerService;
