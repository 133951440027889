import { Button, Modal } from "react-bootstrap";

import { FC } from "react";

interface DeletePolicyProps {
  show: boolean;
  onHide: () => void;
  onDelete: () => void;
  onCancel: () => void;
}

const DeletePolicy: FC<DeletePolicyProps> = (props) => {
  return (
    <div className="modal-container">
      <Modal
        {...props}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this policy?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="rounded-0"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="rounded-0"
            onClick={props.onDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* )} */}
    </div>
  );
};

export default DeletePolicy;
