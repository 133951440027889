import { useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { PageTitle } from "../../../shared/components";
import { BiPlus } from "react-icons/bi";
import ClaimTable from "./components/ClaimTable";
import {
  useGetAllClaimsQuery,
} from "../../../../services/claim/claim.service";
import { ClaimRequest } from "../../../../model/request/claim.request";
import AddClaimModal from "./components/modals/AddClaimsModal";

const Claims = () => {
  const [modalShow, setModalShow] = useState(false);
  const { isLoading, data } = useGetAllClaimsQuery();

  const handleAddClaim = async (claim: ClaimRequest) => {
    try {
      console.log("pass");
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  return (
    <section className="main-page">
      <Container fluid className="px-5 pt-5">
        <PageTitle
          title="Claims"
          hasCTA={true}
          ctaLabel="Add Claim"
          ctaIcon={<BiPlus />}
          ctaAction={() => setModalShow(true)}
        />
        <div className="glass-bg-datable mt-2">
          {isLoading ? <Spinner /> : <ClaimTable claimData={data?.data} />}
        </div>
        <AddClaimModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          onAddClaim={handleAddClaim}
          isEditMode={false}
        />
      </Container>
    </section>
  );
};

export default Claims;
