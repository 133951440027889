import { FC, ReactNode } from "react";
import { useState, useEffect } from "react";
import {
  Table,
  Card,
  Button,
  Pagination,
  InputGroup,
  FloatingLabel,
  Form,
  Dropdown,
} from "react-bootstrap";
import FilterAndExportCustomer from "./modals/FilterAndExportCustomerModal";
import { CustomerResponse } from "../../../../../model/response/customer.response";
import { BsThreeDotsVertical } from "react-icons/bs";
import React from "react";
import {
  useAddCustomerMutation,
  useDeleteCustomerMutation,
  useFilterCustomerMutation,
  useSearchCustomerMutation,
} from "../../../../../services/customer/customer.service";
import { toast } from "react-toastify";
import DeleteCustomer from "./modals/DeleteCustomer";
import AddCustomerModal from "./modals/AddCustomerModal";
import { CustomerRequest } from "../../../../../model/request/customer.request";
import { Typeahead } from "react-bootstrap-typeahead";
import ViewCustomerPolicies from "./modals/ViewCustomerPolicies";
import { useFilterPolicyMutation } from "../../../../../services/policy/policy.service";
import { PolicyRequest } from "../../../../../model/request/policy.request";
import { AiOutlineFileExcel } from "react-icons/ai";

type CustomerTableProps = {
  customerData?: CustomerResponse[];
};
interface CustomToggleProps {
  children: ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CustomerTable: FC<CustomerTableProps> = (props) => {
  const [deleteCustomer, { isLoading: isDeleting }] =
    useDeleteCustomerMutation();
  const [addCustomer] = useAddCustomerMutation();
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [editCustomerModalShow, setEditCustomerModalShow] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCustomer, { data }] = useSearchCustomerMutation();
  const [customerId, setCustomerId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [filteredCustomerData, setFilteredCustomerData] = useState<
    CustomerResponse[]
  >([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(
    null
  );
  const [filterCriteria, setFilterCriteria] = useState("");
  const [filterModalShow, setFilterModalShow] = React.useState(false);
  const [filterCustomer, { data: filteredData }] = useFilterCustomerMutation();
  const [viewCustomerPoliciesModalShow, setViewCustomerPoliciesModalShow] =
    React.useState(false);
  const [filterPolicy] = useFilterPolicyMutation();
  const [policyDataBasedOnCustomer, setPolicyDataBasedOnCustomer] = useState<
    PolicyRequest[]
  >([]);

  const handleDeleteAction = (customerId: number) => {
    setSelectedCustomerId(customerId);
    setDeleteModalShow(true);
  };

  const pageSize = 10; // Number of items to display per page
  const totalPages = props.customerData?.length
    ? Math.ceil(props.customerData.length / pageSize)
    : 0;

  // Calculate the start and end indices based on the current page and page size
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const handleSelectCustomer = (customer: any) => {
    if (customer && customer.length > 0) {
      const selectedCustomer = customer[0];
      setCustomerId(selectedCustomer?.id);
      setSelectedCustomer(selectedCustomer);
      setSearchQuery(selectedCustomer?.name);

      if (searchQuery.trim() === "") {
        const filteredData =
          props.customerData?.filter(
            (data) => data.id === selectedCustomer?.id
          ) ?? [];

        setFilteredCustomerData(filteredData);
      }
    }
  };

  function formatQueryString(form: Map<string, string>): string {
    let queryString: string = "";

    form.forEach((value: string, key: string) => {
      if (value !== "") {
        queryString += queryString === "" ? "?" : "&";
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}`;
      }
    });
    // For debugging purposes

    return queryString;
  }

  const handleInputChange = (
    text: any,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name: string = e.target.value;
    setSearchQuery(name);
    if (name.trim() !== "") {
      searchCustomer(name).unwrap();
      const filteredData =
        props.customerData?.filter((data) =>
          data.name.toLowerCase().includes(searchQuery.toLowerCase())
        ) ?? [];
      setFilteredCustomerData(filteredData);
    } else {
      setFilteredCustomerData(props.customerData ?? []);
    }
  };

  const handleFilter = async () => {
    const form: Map<string, string> = new Map(Object.entries(filterCriteria));
    const queryString = formatQueryString(form);
    // Call the filterPolicy mutation with the filter criteria
    const response = await filterCustomer(queryString).unwrap();
    // Update the filteredPolicyData state with the filtered data
    setFilteredCustomerData(response?.data ?? []);
    setFilterModalShow(false);
  };
  // console.log("stringify", param)    // console.log("stringify", param)
  useEffect(() => {
    if (searchQuery.trim() === "") {
      // If search query is empty, show all customer data
      setFilteredCustomerData(props.customerData || []);
    } else {
      // If search query is not empty, filter the customer data based on the query
      const filteredData =
        props.customerData?.filter((data) =>
          data?.name?.includes(searchQuery)
        ) || [];
      setFilteredCustomerData(filteredData);
    }
    // console.log("data here:",policyDataBasedOnCustomer )
  }, [props.customerData, searchQuery, policyDataBasedOnCustomer]);

  const handleConfirmDelete = async () => {
    try {
      let response = await deleteCustomer(selectedCustomerId);
      setSelectedCustomerId(null);
      setDeleteModalShow(false);
      toast("Customer deleted successfully");
    } catch (error) {
      toast(`error: ${error} `);
      setDeleteModalShow(false);
    }
    // setSelectedCustomerId(null);
  };

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const renderPageItems = () => {
    const pageItems = [];

    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  const handleAddCustomer = async (customer: CustomerRequest) => {
    try {
      await addCustomer(customer);
    } catch (e) {
      toast(`Error:, ${e}`);
    }
  };

  const onEditCustomer = (customer: CustomerRequest) => {
    setSelectedCustomer(customer);
    setEditCustomerModalShow(true);
  };

  const CustomToggle = React.forwardRef<HTMLButtonElement, CustomToggleProps>(
    ({ children, onClick }, _ref) => (
      <div className="position-relative">
        <Button variant="link" onClick={onClick} className="p-0">
          <BsThreeDotsVertical size={25} />
          {children}
        </Button>
      </div>
    )
  );

  const exportFilteredData = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredCustomerData);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "filtered_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data: any) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((item: any) => Object.values(item).join(","));
    return [header, ...rows].join("\n");
  };

  const handleViewCustomerPolicies = async (customerId: number) => {
    setSelectedCustomerId(customerId);
    const traceCustomerID = customerId.toString();
    const param = { customerId: traceCustomerID };
    const customer: Map<string, string> = new Map(Object.entries(param));
    const queryString = formatQueryString(customer);
    const response = await filterPolicy(queryString).unwrap();
    setPolicyDataBasedOnCustomer(response?.data || []);
    setViewCustomerPoliciesModalShow(true);
  };

  return (
    <div className="p-4 main-card-bg ">
      <div className="d-flex justify-content-between mb-3">
        <div className="w-25">
          <Form className="modal-forms ">
            <InputGroup className="mb-3">
              <FloatingLabel label="">
                <Typeahead
                  id="searchCustomer"
                  labelKey="name"
                  options={data?.data || []}
                  placeholder="Search by customer name"
                  size="lg"
                  onChange={handleSelectCustomer}
                  onInputChange={handleInputChange}
                  inputProps={{
                    className: "my-custom-classname rounded-0 ",
                    style: {
                      fontSize: "15px",
                    },
                  }}
                />
              </FloatingLabel>
            </InputGroup>
          </Form>
        </div>
        <div>
          <Button
            variant="light"
            className="px-4 shadow-lg rounded-0 border-0 "
            onClick={() => setFilterModalShow(true)}
          >
            Filter
          </Button>
          <Button
            variant="light"
            className="px-4 shadow-lg mx-2 rounded-0 border-0 "
            onClick={() => exportFilteredData()}
          >
            Export
          </Button>
        </div>
      </div>
      <Card className="policy-table-card bg-white border-0 rounded-0 shadow-lg ">
        <Card.Body>
          <Table className="policy-table">
            <thead className="sticky-header bg-white py-3">
              <tr className="table-body">
                <th>Name</th>
                <th>Phone Number</th>
                <th>Email</th>
                <th>Customer Type</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>

            <tbody className="text-muted table-body">
              <>
                {filteredCustomerData && filteredCustomerData?.length !== 0 ? (
                  <>
                    {filteredCustomerData
                      ?.slice(startIndex, endIndex)
                      .map((data, key) => (
                        <tr className="" key={key}>
                          <td>{data.name}</td>
                          <td>{data.msisdn}</td>
                          <td>{data.email}</td>
                          <td>{data.customerType}</td>
                          <td className="text-end">
                            <Dropdown>
                              <Dropdown.Toggle
                                as={CustomToggle}
                              ></Dropdown.Toggle>
                              <Dropdown.Menu
                                title=""
                                className="dropdown-menu position-absolute"
                              >
                                <Dropdown.Item
                                  onClick={() =>
                                    handleViewCustomerPolicies(data.id)
                                  }
                                >
                                  View Customer Policies
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    onEditCustomer(data as CustomerRequest)
                                  }
                                >
                                  Edit Customer
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleDeleteAction(data.id)}
                                >
                                  Delete Customer
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={12}
                      className="text-center fw-normal fs-2 border-0"
                    >
                      <div>
                        <AiOutlineFileExcel
                          className=""
                          style={{ fontSize: "100px", marginTop: "50px" }}
                        />
                      </div>
                      No Data Found
                    </td>
                  </tr>
                )}
              </>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <div className="bg-light mt-2 ">
        <div className="d-flex justify-content-end ">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {renderPageItems()}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </div>
      </div>

      <AddCustomerModal
        show={editCustomerModalShow}
        onHide={() => setEditCustomerModalShow(false)}
        onAddCustomer={handleAddCustomer}
        selectedCustomer={selectedCustomer}
        isEditMode={true}
      />

      <FilterAndExportCustomer
        show={filterModalShow}
        onHide={() => setFilterModalShow(false)}
        filterCriteria={filterCriteria}
        setFilterCriteria={setFilterCriteria}
        handleFilter={handleFilter}
      />
      <DeleteCustomer
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        onDelete={handleConfirmDelete}
        onCancel={() => setDeleteModalShow(false)}
      />
      <ViewCustomerPolicies
        show={viewCustomerPoliciesModalShow}
        onHide={() => setViewCustomerPoliciesModalShow(false)}
        customerPolicies={policyDataBasedOnCustomer}
      />
    </div>
  );
};

export default CustomerTable;
