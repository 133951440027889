import { Col, Container, Row } from "react-bootstrap";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { SideBar } from "../components";
import { ToastContainer } from "react-toastify";
import useAuth from "../../../core/hook/useAuth";

const MainLayout = () => {
  const { auth } = useAuth();
  const location = useLocation();

  return !auth?.user ? (
    <Navigate to="/auth/login" state={{ from: location }} replace={true} />
  ) : (
    <Container fluid>
      <ToastContainer />
      <Row>
        <SideBar />
        <Col lg={{ offset: 2 }} className="min-vh-100 p-0 bg-light">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};
export default MainLayout;
