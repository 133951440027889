import { useState, useEffect, FC, ChangeEvent } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Modal,
  Image,
  FloatingLabel,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-toastify";
import Logo from "../../../../../../assets/images/logo.png";
import { CommissionRequest } from "../../../../../../model/request/commission.request";
import {
  useGetCommissionByIdMutation,
  useUpdateCommissionByIdMutation,
} from "../../../../../../services/commission/commission.service";
import { CommissionResponse } from "../../../../../../model/response/commission.response";

type CommissionTableProps = {
  show: boolean;
  onHide: () => void;
  commissionId: number | null;
};

const AddCommission: FC<CommissionTableProps> = (props) => {
  const { commissionId } = props;
  const [getCommissionById, { data, error, isLoading }] =
    useGetCommissionByIdMutation();
  const [updateCommissionById] = useUpdateCommissionByIdMutation();

  // Fetch the commission data by ID
  useEffect(() => {
    getCommissionById(commissionId);
  }, [getCommissionById, commissionId]);

  // Handle the API response and update the commissionData state
  useEffect(() => {
    if (data && data.data) {
      setCommissionData(data.data);
    }
    if (error) {
      // toast("Failed to fetch commission data.");
    }
  }, [data, error]);

  const [loading, setLoading] = useState(false);
  const [commissionData, setCommissionData] = useState<CommissionResponse[]>(
    []
  );

  const [formData, setFormData] = useState<CommissionResponse>({
    policy: undefined,
    customer: undefined,
    policyNumber: "",
    commissionRate: "",
    grossCommission: 0,
    netCommission: 0,
    id: 0,
    insuranceCompany: "",
    dateReceived: "",
    reference: "",
    commissionType: "",
    narration: "",
    effectiveDate: "",
    expiryDate: "",
    insuranceDeliveryDate: "",
    amountReceived: 0,
  });

  // Update the form data when the commissionData is fetched and has elements
  useEffect(() => {
    if (commissionData.length > 0) {
      setFormData(commissionData[0]); // Use the first element of the array
    }
  }, [commissionData]);

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (!formData) return;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = async (commission: CommissionResponse) => {
    setLoading(true);
    try {
      // Create a CommissionRequest object from the CommissionResponse
      const commissionRequest: CommissionRequest = {// You may need to modify this based on the actual property name in the response
        id: commission.id || 0,
        insuranceCompany: commission.insuranceCompany || "",
        amountReceived: commission.amountReceived || 0,
        dateReceived: commission.dateReceived || "",
        reference: commission.reference || "",
        commissionType: commission.commissionType || "",
        narration: commission.narration || "",
      };
  
      // Call the updateCommissionById mutation with the CommissionRequest object
      let response = await updateCommissionById(commissionRequest);
      toast("Commission added successfully");
      props.onHide();
    } catch (e) {
      toast("Failed adding commission, please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="modal-container">
      <Modal
        {...props}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
        <Modal.Header closeButton className="border-0 w-100"></Modal.Header>
        <Modal.Body className="modal-body-style">
          <div className="d-flex justify-content-between px-5 align-items-center header-with-logo-and-modal-title">
            <div>
              <Image alt="img" src={Logo} width={150} />
            </div>
            <div className="text-end w-100 ">
              <span className="text-primary fs-6 fw-semibold">
                ADD COMMISSION
              </span>
            </div>
          </div>
          <div className="modal-form mt-5 px-5">
            <Form className="modal-forms">
              <Row className="pb-2">
                <Form.Group as={Col}>
                  <FloatingLabel label="Insurance Company">
                    <Form.Control
                      type="text"
                      name="insuranceCompany"
                      className="rounded-0 border-primary"
                      value={formData.policy?.insuranceCompany || ""}
                      onChange={handleFormChanged}
                      disabled
                    ></Form.Control>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col}>
                  <FloatingLabel label="Amount Receive">
                    <Form.Control
                      type="number"
                      name="amountReceived"
                      className="rounded-0 border-primary"
                      value={formData.amountReceived || 0}
                      onChange={handleFormChanged}
                    ></Form.Control>
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="pb-2">
                <Form.Group as={Col} hidden={formData.amountReceived === 0}>
                  <FloatingLabel label="Date Of Request">
                    <Form.Control
                      type="date"
                      name="dateOfRequest"
                      className="rounded-0 border-primary"
                      value={formData.policy?.dateOfRequest }
                      onChange={handleFormChanged}
                      disabled
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col}>
                  <FloatingLabel label="Reference">
                    <Form.Control
                      type="text"
                      name="reference"
                      className="rounded-0 border-primary"
                      value={formData.reference}
                      onChange={handleFormChanged}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="pb-2">
                <Form.Group as={Col}>
                  <FloatingLabel label="Premium Paid">
                    <Form.Control
                      type="text"
                      name="premiumPaid"
                      className="rounded-0 border-primary"
                      value={formData.policy?.premiumPaid}
                      onChange={handleFormChanged}
                      disabled
                    ></Form.Control>
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col}>
                  <FloatingLabel label="Narration">
                    <Form.Control
                      type="text"
                      name="narration"
                      className="rounded-0 border-primary"
                      value={formData.narration}
                      onChange={handleFormChanged}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
              <Row className="pb-2">
                <Form.Group as={Col}>
                  <FloatingLabel label="Policy Type">
                    <Form.Control
                      type="text"
                      name="policyType"
                      className="rounded-0 border-primary"
                      value={formData.policy?.policyType}
                      onChange={handleFormChanged}
                      disabled
                    />
                  </FloatingLabel>
                </Form.Group>
                <Form.Group as={Col}>
                  <FloatingLabel label="Date Received">
                    <Form.Control
                      type="date"
                      name="dateReceived"
                      className="rounded-0 border-primary"
                      value={formData.dateReceived}
                      onChange={handleFormChanged}
                    />
                  </FloatingLabel>
                </Form.Group>
              </Row>
            </Form>

            <div className="text-end mt-2">
              <Button
                className="rounded-0 px-5"
                onClick={() => submitForm(formData)}
              >
                {loading ? (
                  <Spinner
                    style={{ fontSize: "2px", height: "20px", width: "20px" }}
                  />
                ) : (
                  <span> Add Commission</span>
                )}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* )} */}
    </div>
  );
};

export default AddCommission;
