import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AppConstants } from "../../core/app-constants";
import { QuoteResponse } from "../../model/response/quote.response";
import { BaseResponse } from "../../model/response/base-response";

export const quoteService = createApi({
  reducerPath: "quoteService",
  baseQuery: fetchBaseQuery({
    baseUrl: `${AppConstants.baseUrl}`,
    // headers: { "Content-Type": "application/json; charset=UTF-8" },
  }),
  tagTypes: ["Quotes"],
  endpoints: (builder) => ({
    getAllQuotes: builder.query<BaseResponse<QuoteResponse[]>, void>({
      query: () => ({
        url: "/quotes?page=1&size=1000000000",
        method: "GET",
      }),
      providesTags: ["Quotes"],
    }),
    getQuoteById: builder.mutation<BaseResponse<QuoteResponse[]>, number>({
      query: (quoteId) => ({
      url:`/quotes/${quoteId}`,
      method: "GET",
    }),
    invalidatesTags: ["Quotes"],
    }),
    filterQuotes: builder.mutation<BaseResponse<QuoteResponse[]>, any>({
      query: (params) => ({
        url: `/quotes`+ params,
        method: "GET",
      }),
      invalidatesTags: ["Quotes"],
  }),
  searchQuotes: builder.mutation<BaseResponse<QuoteResponse[]>, any>({
    query: (params) => ({
      url: `/quotes/${params}`,
      method: "GET",
    }),
    invalidatesTags: ["Quotes"],
}),
}),
});

export const {
 useGetAllQuotesQuery,
 useGetQuoteByIdMutation,
 useFilterQuotesMutation,
 useSearchQuotesMutation,
} = quoteService;
