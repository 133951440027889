import { Button,  Modal, } from "react-bootstrap";

import { FC } from "react";

interface DeleteCustomerProps {
  show: boolean;
  onHide: () => void;
  onDelete: () => void;
  onCancel:() => void;
  // other props for your component
}

const  DeleteCustomer: FC<DeleteCustomerProps> = (props) => {
  return (
    <div className="modal-container">
      <Modal
        {...props}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
         <Modal.Header closeButton>
          <Modal.Title>Delete Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this customer?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="rounded-0"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="rounded-0"
            onClick={props.onDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* )} */}
    </div>
  );
}

export default DeleteCustomer;
