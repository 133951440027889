import { Button, Row, Col, Image, Form, FloatingLabel } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Logo from "../../../../../../assets/images/logo.png";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { InsuranceCompanies } from "../../../../../shared/components/InsuranceCompanies";
// import { PolicyType } from "../../../../../../model/response/policy-type.response";
import {PolicyType} from "../../../../../../model/response/policy-type.response"
import { usePolicyTypesLsitQuery } from "../../../../../../services/policy/policy.service";

type FilterAndExportClaimModalProps = {
  show: boolean;
  onHide: () => void;
  filterCriteria: any;
  setFilterCriteria: any;
  handleFilter: () => void;
};

const FilterAndExportClaim: FC<FilterAndExportClaimModalProps> = (props) => {
  const { show, onHide, setFilterCriteria, filterCriteria } = props;
  const { data: policyTypeData } = usePolicyTypesLsitQuery();
  const [showPolicyTypes, setShowPolicyTypes] = useState<PolicyType[]>();
  const handleApplyFilter = () => {
    // Update the filterCriteria state with the generated queryString
    props.handleFilter();
  };

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterCriteria({
      ...filterCriteria,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setShowPolicyTypes(policyTypeData?.data);
    console.log("show", showPolicyTypes);
  }, [policyTypeData, showPolicyTypes]);


  return (
    <div className="modal-container">
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
        <Modal.Header closeButton className="border-0 w-100"></Modal.Header>
        <Modal.Body className="modal-body-style p-5 mt-">
          <Row className="align-items-top">
            <Col>
              <Image src={Logo} width={150} />
            </Col>
            <Col md="auto">Filter and Export Claims</Col>
          </Row>

          <Form className="modal-forms mt-5">
            <Row className="pb-2">
              <Form.Group as={Col}>
                <FloatingLabel label="Client Classification">
                  <Form.Control
                    as="select"
                    type="text"
                    className="rounded-0 border-primary"
                    name="clientClassification"
                    onChange={handleFormChanged}
                    value={filterCriteria.clientClassification}
                  >
                    <option value="Retail">Retail</option>
                    <option value="Corporate">Corporate</option>
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label="Insurance Company">
                  <Form.Control
                    as="select"
                    type="text"
                    name="insuranceCompany"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={filterCriteria.insuranceCompany}
                  >
                    {InsuranceCompanies.map((company, index) => (
                      <option key={index}> {company} </option>
                    ))}
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="pb-2">
              <Form.Group as={Col}>
                <FloatingLabel label="Current Status">
                  <Form.Control
                    as="select"
                    type="text"
                    className="rounded-0 border-primary"
                    name="currentStatus"
                    onChange={handleFormChanged}
                    value={filterCriteria.currentStatus}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Policy Type">
                  <Form.Control
                    as="select"
                    type="text"
                    name="policyType"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={filterCriteria.policyType}
                  >
                    {showPolicyTypes &&
                showPolicyTypes?.map((policy:any, index:any) => (
                  <option key={index} value={policy.value}>
                    {" "}
                    {policy.displayName}{" "}
                  </option>
                ))}
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="mt-3 text-end">
              <div className="text-end  ">
                <Button
                  className="rounded-0 px-5"
                  onClick={() => handleApplyFilter()}
                >
                  Filter
                </Button>
              </div>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FilterAndExportClaim;
