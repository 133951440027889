import React, { FC, ReactNode, useEffect } from "react";
import { useState } from "react";
import {
  Table,
  Card,
  Button,
  Pagination,
  Dropdown,
  FloatingLabel,
  InputGroup,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { AiOutlineFileExcel } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
import { CommissionRequest } from "../../../../../model/request/commission.request";
import { CommissionResponse } from "../../../../../model/response/commission.response";
import {
  useDeleteCommissionByIdMutation,
  useFilterCommissionMutation,
  useSearchCommissionMutation,
} from "../../../../../services/commission/commission.service";
import AddCommission from "./modals/AddCommissionModal";
import DeleteCommission from "./modals/DeleteCommission";
import FilterAndExportCommission from "./modals/FilterAndExportCommissionModal";

type CommissionTableProps = {
  commissionData?: CommissionResponse[];
};

interface CustomToggleProps {
  children: ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CommissionTable: FC<CommissionTableProps> = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [deleteCommissionById] = useDeleteCommissionByIdMutation();

  const CustomToggle = React.forwardRef<HTMLButtonElement, CustomToggleProps>(
    ({ children, onClick }, _ref) => (
      <div className="position-relative">
        <Button variant="link" onClick={onClick} className="p-0">
          <BsThreeDotsVertical size={25} />
          {children}
        </Button>
      </div>
    )
  );

  const [selectedCommissionId, setSelectedCommissionId] = useState<
    number | null
  >(null);
  const [commissionId, setCommissionId] = useState<number | null>(null);
  const [filterModalShow, setFilterModalShow] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCommission, { data }] = useSearchCommissionMutation();
  const [filteredCommissionData, setFilteredCommissionData] = useState<
    CommissionResponse[]
  >(props.commissionData ?? []);
  const [selectedCommission, setSelectedCommission] =
    useState<CommissionRequest>();
  const [filterCriteria, setFilterCriteria] = useState("");
  const [filterCommission, { data: filteredData }] =
    useFilterCommissionMutation();

  const handleSelectCommission = (commission: any) => {
    if (commission && commission.length > 0) {
      const selectedCommission = commission[0];
      setCommissionId(selectedCommission?.id);
      setSelectedCommission(selectedCommission);
      setSearchQuery(selectedCommission?.policyId);

      if (selectedCommission && selectedCommission.policyId.trim() === "") {
        const filteredData =
          props.commissionData?.filter(
            (data) => data.id === selectedCommission.id
          ) ?? [];
        setFilteredCommissionData(filteredData);
      }
    }
  };

  const handleInputChange = (
    text: any,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const policyNumber: string = e.target.value;
    setSearchQuery(policyNumber);

    if (policyNumber.trim() !== "") {
      // Update the filtered data based on the search query
      const filteredData =
        props.commissionData?.filter((data) =>
          data.policyNumber.toLowerCase().includes(policyNumber.toLowerCase())
        ) ?? [];
      setFilteredCommissionData(filteredData);
    } else {
      // If the search query is empty, show all commission data
      setFilteredCommissionData(props.commissionData ?? []);
    }
  };

  function formatQueryString(form: Map<string, string>): string {
    let queryString: string = "";

    form.forEach((value: string, key: string) => {
      if (value !== "") {
        queryString += queryString === "" ? "?" : "&";
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}`;
      }
    });
    // For debugging purposes

    return queryString;
  }

  useEffect(() => {
    if (searchQuery.trim() === "") {
      // If search query is empty, show all commission data
      setFilteredCommissionData(props.commissionData || []);
    } else {
      // If search query is not empty, filter the commission data based on the query
      const filteredData =
        props.commissionData?.filter((data) =>
          data.policyNumber.includes(searchQuery)
        ) || [];
      setFilteredCommissionData(filteredData);
    }
  }, [props.commissionData, searchQuery]);

  const handleFilter = async () => {
    const form: Map<string, string> = new Map(Object.entries(filterCriteria));
    const queryString = formatQueryString(form);
    // Call the filterPolicy mutation with the filter criteria
    const response = await filterCommission(queryString).unwrap();
    // Update the filteredPolicyData state with the filtered data
    setFilteredCommissionData(response?.data ?? []);
    setFilterModalShow(false);
  };

  const handleDeleteAction = (commissionId: number | undefined) => {
    setSelectedCommissionId(commissionId ?? null);
  };

  const handleCancelDelete = () => {
    setSelectedCommissionId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      let response = await deleteCommissionById(selectedCommissionId);
      toast("Commission deleted successfully");
      setSelectedCommissionId(null);
      // console.log("Commission deleted !", response);
    } catch (error) {
      toast(`Error deleting commission:, ${error}`);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items to display per page
  const totalPages = props.commissionData?.length
    ? Math.ceil(props.commissionData.length / pageSize)
    : 0;

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const renderPageItems = () => {
    const pageItems = [];

    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  // Calculate the start and end indices based on the current page and page size
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const exportFilteredData = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredCommissionData);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "filtered_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data: any) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((item: any) => Object.values(item).join(","));
    return [header, ...rows].join("\n");
  };

  return (
    <div className="p-4 main-card-bg">
      <div className="d-flex justify-content-between mb-3">
        <div className="w-25">
          <InputGroup className="mb-3">
            <FloatingLabel label="">
              <Typeahead
                id="searchCommission"
                labelKey="policyNumber"
                options={props.commissionData || []}
                placeholder="Search by policy Number"
                size="lg"
                onChange={handleSelectCommission}
                onInputChange={handleInputChange}
                inputProps={{
                  className: "my-custom-classname rounded-0 ",
                  style: {
                    fontSize: "15px",
                  },
                }}
              />
            </FloatingLabel>
          </InputGroup>
        </div>
        <div>
          <Button
            variant="light"
            className="px-4 shadow-lg rounded-0 border-0"
            onClick={() => setFilterModalShow(true)}
          >
            Filter
          </Button>
          <Button
            variant="light"
            className="px-4 shadow-lg mx-2 rounded-0 border-0"
            onClick={() => exportFilteredData()}
          >
            Export
          </Button>
        </div>
      </div>
      <Card className="policy-table-card bg-white border-0 rounded-0 shadow-lg">
        <Card.Body>
          <Table className="policy-table">
            <thead className="sticky-header py-3">
              <tr className="table-body ">
              <th className="text-start">Customer</th>
                <th className="text-start">Policy Number</th>
                <th className="text-start">Insurance Company</th>
                <th className="text-start">Gross Commission</th>
                <th className="text-start">Net Commission</th>
                <th className="text-start">Amount Received</th>
                <th className="text-start">Reference</th>
                <th className="text-start"> Date Received</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>

            <tbody className="text-muted table-body">
              <>
                {filteredCommissionData &&
                filteredCommissionData?.length !== 0 ? (
                  <>
                    {filteredCommissionData
                      ?.slice(startIndex, endIndex)
                      .map((data, key) => (
                        <tr key={key}>
                          <td>{data.policy?.customer?.name}</td>
                          <td>{data.policy?.policyNumber}</td>
                          <td>{data.policy?.insuranceCompany}</td>
                          <td>{data.grossCommission}</td>
                          <td>{data.netCommission}</td>
                          <td>{data.amountReceived || 0}</td>
                          <td>{data.reference || "N/A"}</td>
                          <td>{data.dateReceived}</td>

                          <td className="text-end">
                            <Dropdown>
                              <Dropdown.Toggle
                                as={CustomToggle}
                              ></Dropdown.Toggle>
                              <Dropdown.Menu
                                title=""
                                className="dropdown-menu position-absolute"
                              >
                                <Dropdown.Item
                                  onClick={() => {
                                    if (data.id) {
                                      setCommissionId(data.id);
                                      setModalShow(true);
                                    }
                                  }}
                                >
                                  Edit Commission
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleDeleteAction(data.id)}
                                >
                                  Delete Commission
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={12}
                      className="text-center fw-normal fs-2 border-0"
                    >
                      <div>
                        <AiOutlineFileExcel
                          className=""
                          style={{ fontSize: "100px", marginTop: "50px" }}
                        />
                      </div>
                      No Data Found
                    </td>
                  </tr>
                )}
              </>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <div className="bg-light mt-2 ">
        <div className="d-flex justify-content-end ">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {renderPageItems()}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </div>
      </div>

      <AddCommission
        show={modalShow}
        onHide={() => setModalShow(false)}
        commissionId={commissionId}
      />
      <DeleteCommission
        show={selectedCommissionId !== null}
        onHide={handleCancelDelete}
        onDelete={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
      <FilterAndExportCommission
        show={filterModalShow}
        onHide={() => setFilterModalShow(false)}
        filterCriteria={filterCriteria}
        setFilterCriteria={setFilterCriteria}
        handleFilter={handleFilter}
      />
    </div>
  );
};

export default CommissionTable;
