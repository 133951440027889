import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthLayout, MainLayout } from "../presentation/shared/layouts";
import { LoginPage } from "../presentation/pages/auth";
import { Claims, Commissions, Customers, Dashboard ,Quote, Policies, Payment} from "../presentation/pages/main/";


const AppRouting = () => {
  return (
    <HashRouter>
      <Routes>
         {/* Auth Routes */}
         <Route path="/auth" element={<AuthLayout />}>
          <Route index element={<Navigate replace to={"login"} />} />
          <Route path="login" element={<LoginPage />} />
        </Route>
        {/* Main Routes */}
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Navigate replace to={"home"} />} />
          <Route path="home" element={<Dashboard />} />
          <Route path="policies" element={<Policies/>} />
          <Route path="customers" element={<Customers/>} />
          <Route path="commission" element={<Commissions/>} />
          <Route path="claims" element={<Claims/>} />
          <Route path="payment" element={<Payment/>} />
          <Route path="quote" element={<Quote/>} />
        </Route>
       
        {/* Not found */}
        <Route path="*" element={<h1>Page not found</h1>}></Route>
      </Routes>
    </HashRouter>
  );
};

export default AppRouting;
