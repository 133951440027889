import { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { CustomerRequest } from "../../../../../../model/request/customer.request";
import { useGetCustomerByIdMutation } from "../../../../../../services/customer/customer.service";

type AddIndividualFormProps = {
  onSubmitForm: (customer: CustomerRequest) => void;
  customer?: CustomerRequest | {};
  isEditMode?: boolean | false;
  button: string | "";
  closeModal: () => void;
};

const AddIndividualForm: FC<AddIndividualFormProps> = (props) => {
  const [getCustomerById, { data }] = useGetCustomerByIdMutation();
  const { customer, isEditMode, button } = props;
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [loading,setLoading] = useState(false);
  const customerData = data?.data || [];
  const [formData, setFormData] = useState<CustomerRequest>(customer || {});

  useEffect(() => {
    if (customerData.length > 0) {
      setFormData(customerData[0] as CustomerRequest);
    }
  }, [customerData]);

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (!formData) return;
    const { name, value } = e.target;
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
    setFormData({ ...formData, [name]: value });
  };

  const onSubmitForm = () => {
    
    const errors: { [key: string]: boolean } = {};

    if (!formData.name) {
      errors.name = true;
    }

    if (!formData.msisdn) {
      errors.msisdn = true;
    }

    if (!formData.email) {
      errors.email = true;
    }

    if (!formData.dateOfBirth) {
      errors.dateOfBirth = true;
    }

    if (!formData.ghanaCardNumber) {
      errors.ghanaCardNumber = true;
    }

    // Set the field errors
    setFieldErrors(errors);

    // If there are no errors, proceed with form submission
    if (Object.keys(errors).length === 0) {
      // Reset the field errors
      setFieldErrors({});
      setLoading(true)
      props.onSubmitForm(formData);
      props.closeModal();

    }
  };

  return (
    <Form className="modal-forms">
      <Row className="pb-2">
        <Form.Group as={Col}>
          <FloatingLabel label="Full Name">
            <Form.Control
              type="text"
              name="name"
              className="rounded-0 border-primary"
              value={formData.name}
              onChange={handleFormChanged}
              isInvalid={fieldErrors.name}
              required
            />
          </FloatingLabel>
        </Form.Group>
      </Row>

      <Row className="gx-2 pb-2">
        <Form.Group as={Col}>
          <FloatingLabel label="Phone Number">
            <Form.Control
              type="text"
              className="rounded-0 border-primary"
              name="msisdn"
              value={formData.msisdn}
              onChange={handleFormChanged}
              isInvalid={fieldErrors.msisdn}
              required
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col}>
          <FloatingLabel label="Email Address">
            <Form.Control
              type="text"
              className="rounded-0 border-primary"
              name="email"
              value={formData.email}
              onChange={handleFormChanged}
              isInvalid={fieldErrors.email}
              required
            />
          </FloatingLabel>
        </Form.Group>
      </Row>

      <Row className="gx-2">
        <Form.Group as={Col}>
          <FloatingLabel label="Date of Birth">
            <Form.Control
              type="date"
              className="rounded-0 border-primary"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleFormChanged}
              isInvalid={fieldErrors.dateOfBirth}
              required
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Ghana Card Number">
            <Form.Control
              type="text"
              className="rounded-0 border-primary"
              name="ghanaCardNumber"
              value={formData.ghanaCardNumber}
              onChange={handleFormChanged}
              isInvalid={fieldErrors.ghanaCardNumber}
              required
            />
          </FloatingLabel>
        </Form.Group>
      </Row>

      <Row className="mt-3">
        <Col className="text-end">
          <Button
            type="button"
            size="lg"
            className="rounded-0 px-5"
            onClick={onSubmitForm}
          >
            {loading ? (<Spinner style={{fontSize:"2px",height:"20px",width:"20px"}}/>):(<span>{button} Individual</span>)}
            
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddIndividualForm;
