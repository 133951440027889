import React, { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from "react";
import {
  Col,
  Modal,
  Row,
  Image,
  Form,
  FloatingLabel,
  Button,
  Spinner,
} from "react-bootstrap";
import Logo from "../../../../../../assets/images/logo.png";
import { PolicyRequest } from "../../../../../../model/request/policy.request";
import { PolicyType } from "../../../../../../model/response/policy-type.response";
import { QuoteResponse } from "../../../../../../model/response/quote.response";
import {
  useConvertQuotesMutation,
  usePolicyTypesLsitQuery,
} from "../../../../../../services/policy/policy.service";
import { InsuranceCompanies } from "../../../../../shared/components/InsuranceCompanies";
import { ConvertQuoteRequest } from "../../../../../../model/request/convertQuote.request";
import { PaymentTypes } from "../../../../../shared/components/PaymentTypes";
import { Leads } from "../../../../../shared/components/Leads";

type ConvertQuoteModalProps = {
  show: boolean;
  onHide: () => void;
  selectedQuote?: QuoteResponse;
  onConvertQuote: (quote: ConvertQuoteRequest) => void;
};

export const ConvertQuote: FC<ConvertQuoteModalProps> = (props) => {
  const { show, onHide, selectedQuote } = props;
  const { data: policyTypeData } = usePolicyTypesLsitQuery();
  const [showPolicyTypes, setShowPolicyTypes] = useState<PolicyType[]>();
  const [formData, setFormData] = useState<ConvertQuoteRequest>({});
  const [convertQuotes] = useConvertQuotesMutation();
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>(
    {}
  );

  useEffect(() => {
    setShowPolicyTypes(policyTypeData?.data);
    console.log("show", showPolicyTypes);
  }, [policyTypeData, showPolicyTypes]);
  const [loading,setLoading] = useState(false);

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const requiredFields: (keyof ConvertQuoteRequest)[] = [
      "policyNumber",
      "clientClassification",
      "insuranceCompany",
      "transactionType",
      "transactionCurrency",
      "policyType",
      "sumInsured",
      "exchangeRate",
      "paymentTerms",
      "premiumPaymentChannel",
      "dateOfRequest",
      "effectiveDate",
      "expiryDate",
      "insuranceDeliveryDate",
      "registrationDate",
      "remarks",
      "lead",
      "policyStatus",
      "createdDate",
    ];

    const errors: { [key in keyof ConvertQuoteRequest]: boolean } = {} as {
      [key in keyof ConvertQuoteRequest]: boolean;
    };

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = true;
      }
    });

    setFieldErrors(errors);
console.log("done",Object.keys(errors).length)
    if (Object.keys(errors).length === 2) {
      console.log("waiting")
      try {
        await convertQuotes(formData);
        props.onHide();
      } catch (e) {
        console.log(e);
      }
    }
  };


  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      employee: { id: "1", name: "yaw" },
      quote: selectedQuote! ?? undefined,
    }));
  };

  return (
    <div className="modal-container">
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        className="container-modal"
        aria-labelledby="contained-modal-title-vcenter rounded-0"
        centered
      >
        <Modal.Header closeButton className="border-0 w-100">
          <Row className="align-items-top">
            <Col>
              <Image src={Logo} width={150} />
            </Col>
            <Col md="auto"></Col>
          </Row>
        </Modal.Header>
        <Modal.Body
          className="modal-body-style p-5 mt-"
          style={{ height: "500px", overflowY: "scroll" }}
        >
          <Form className="modal-forms mt-5" >
            <Row className="pb-2 gx-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Policy Number">
                  <Form.Control
                    type="text"
                    className="rounded-0 border-primary"
                    name="policyNumber"
                    value={formData?.policyNumber || ""}
                    onChange={handleFormChanged}
                    required
                    isInvalid={fieldErrors.policyNumber}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Insurance Company">
                  <Form.Control
                    as="select"
                    type="text"
                    name="insuranceCompany"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData?.insuranceCompany}
                    required
                    isInvalid={fieldErrors.insuranceCompany}
                  >
                     <option value="" selected disabled>
                -- select insurance company --
              </option>
                    {InsuranceCompanies.map((company, index) => (
                      <option key={index}>{company}</option>
                    ))}
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="gx-3 pb-2">
              <Form.Group as={Col}>
                <FloatingLabel label="Date of Request">
                  <Form.Control
                    type="date"
                    name="dateOfRequest"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData?.dateOfRequest || ""}
                    isInvalid={fieldErrors.dateOfRequest}
                    required
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Transaction Type">
                  <Form.Control
                    as="select"
                    type="text"
                    className="rounded-0 border-primary"
                    name="transactionType"
                    onChange={handleFormChanged}
                    value={formData?.transactionType || ""}
                    isInvalid={fieldErrors.transactionType}
                    required
                  >
                    <option value="" selected disabled> -- Select Transaction Type --</option>
                    <option value="New Business">New Business</option>
                    <option value="Renewal">Renewal</option>
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="gx-3 pb-2">
              <Form.Group as={Col}>
                <FloatingLabel label="Policy Type">
                  <Form.Control
                    as="select"
                    type="text"
                    name="policyType"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    required
                    value={formData?.policyType || ""}

                    isInvalid={fieldErrors.policyType}
                  >
                    {showPolicyTypes &&
                      showPolicyTypes?.map((policy, index) => (
                        <option key={index} value={policy.value}>
                          {" "}
                          {policy.displayName}{" "}
                        </option>
                      ))}
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Client Classification">
                  <Form.Control
                    as="select"
                    type="text"
                    className="rounded-0 border-primary"
                    name="clientClassification"
                    onChange={handleFormChanged}
                    value={formData?.clientClassification || ""}
                    isInvalid={fieldErrors.clientClassification}
                    required
                  >
                    <option value="" selected disabled>
                      {" "}
                      -- select Client Classification --{" "}
                    </option>
                    <option value="Retail"> Retail</option>
                    <option value="Corporate">Corporate </option>
                    <option value="Digital">Digital </option>
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>
            <Row className="pb-2 gx-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Exchange Rate">
                  <Form.Control
                    type="text"
                    name="exchangeRate"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData?.exchangeRate || ""}
                    isInvalid={fieldErrors.exchangeRate}
                    required
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Transaction Currency">
                  <Form.Control
                    as="select"
                    type="text"
                    name="transactionCurrency"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData?.transactionCurrency || ""}
                    isInvalid={fieldErrors.transactionCurrency}
                    required
                  >
                    <option value="" selected disabled >-- Select Transaction Currency --</option>
                    <option value="USD">USD</option>
                    <option value="GHS">GHS</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="pb-2 gx-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Sum insured (Transaction Currency)">
                  <Form.Control
                    type="text"
                    name="sumInsured"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData?.sumInsured || ""}
                    isInvalid={fieldErrors.sumInsured}
                    required
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Payment Terms">
                  <Form.Control
                    as="select"
                    type="text"
                    name="paymentTerms"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData?.paymentTerms || ""}
                    isInvalid={fieldErrors.paymentTerms}
                    required
                  >
                    <option value="" selected disabled >-- Select Payment Term --</option>
                   
                    {PaymentTypes.map((payments, index) => (
                      <option key={index}>{payments}</option>
                    ))}
                   
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="pb-2 gx-3">
              <Form.Group as={Col}>
                <FloatingLabel label="Premium Payment Channel">
                  <Form.Control
                    as="select"
                    type="text"
                    name="premiumPaymentChannel"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData?.premiumPaymentChannel || ""}
                    isInvalid={fieldErrors.premiumPaymentChannel}
                    required
                  >
                    <option value="" selected disabled >
                      -- Select Premium Payment Channel --
                    </option>

                    <option value="USSD">USSD</option>
                    <option value="Direct">Direct</option>
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="pb-2 gx-3">
              <Form.Group as={Col}>
                <FloatingLabel label=" Effective Date">
                  <Form.Control
                    type="date"
                    name="effectiveDate"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData?.effectiveDate || ""}
                    required
                    isInvalid={fieldErrors.effectiveDate}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Remark">
                  <Form.Control
                    type="text"
                    name="remarks"
                    className="rounded-0 border-primary"
                    onChange={handleFormChanged}
                    value={formData?.remarks || ""}
                    required
                    isInvalid={fieldErrors.remarks}
                  />
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="gx-3 pb-2">
              <Form.Group as={Col}>
                <FloatingLabel label="Expiry date">
                  <Form.Control
                    type="date"
                    className="rounded-0 border-primary"
                    name="expiryDate"
                    onChange={handleFormChanged}
                    value={formData?.expiryDate || ""}
                    required
                    isInvalid={fieldErrors.expiryDate}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group as={Col}>
                <FloatingLabel label="Policy Status">
                  <Form.Control
                    as="select"
                    type="text"
                    className="rounded-0 border-primary"
                    name="policyStatus"
                    onChange={handleFormChanged}
                    value={formData?.policyStatus || ""}
                    required
                    isInvalid={fieldErrors.policyStatus}
                  >
                     <option value="" selected disabled >-- Select policy status</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive </option>
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>

            <Row className="gx-3 pb-2">
              <Form.Group as={Col}>
                <FloatingLabel label="Insurance Delivery Date">
                  <Form.Control
                    type="date"
                    className="rounded-0 border-primary"
                    name="insuranceDeliveryDate"
                    onChange={handleFormChanged}
                    value={formData?.insuranceDeliveryDate || ""}
                    required
                    isInvalid={fieldErrors.insuranceDeliveryDate}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group as={Col}>
                <FloatingLabel label="Lead">
                  <Form.Control
                    as="select"
                    type="text"
                    className="rounded-0 border-primary"
                    name="lead"
                    onChange={handleFormChanged}
                    value={formData?.lead || ""}
                    required
                    isInvalid={fieldErrors.lead}
                  >
                     <option value="" selected disabled >-- Select Lead -- </option>
                     {Leads.map((lead,index)=>(
                      <option value={index}>{lead} </option>
                     ))}
                  </Form.Control>
                </FloatingLabel>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Row className="mt-3 text-end">
            <div className="text-end  ">
              <Button
                className="rounded-0 px-5"
                type="submit"
                onClick={handleSubmit}
                // onClick={() => handleSubmit(formData!)}
              >
              {loading ? (<Spinner style={{fontSize:"2px",height:"20px",width:"20px"}}/>):(<span>Convert</span>)}                
              </Button>
            </div>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
