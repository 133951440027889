import { useState, FC, ReactNode, useEffect } from "react";
import {
  Table,
  Card,
  Button,
  Pagination,
  Dropdown,
  InputGroup,
  FloatingLabel,
} from "react-bootstrap";
import { QuoteResponse } from "../../../../../model/response/quote.response";
import { BsThreeDotsVertical } from "react-icons/bs";
import React from "react";
import { ConvertQuote } from "./modal/convertQuote";
import {
  useFilterQuotesMutation,
  useGetQuoteByIdMutation,
  useSearchQuotesMutation,
} from "../../../../../services/quote/quote.service";
import FilterQuote from "./modal/FilterModal";
import { Typeahead } from "react-bootstrap-typeahead";
import { AiOutlineFileExcel } from "react-icons/ai";
type QuoteTableProps = {
  quoteData?: QuoteResponse[];
};
interface CustomToggleProps {
  children: ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const QuoteDataTable: FC<QuoteTableProps> = (props) => {
  const { quoteData } = props;
  const [convertModalShow, setConvertModalShow] = React.useState(false);
  const [getQuoteById] = useGetQuoteByIdMutation();
  const [filterQuotes] = useFilterQuotesMutation();
  const [selectedQuote, setSelectedQuote] = useState<
    QuoteResponse | undefined
  >();
  const [filteredData, setFilteredData] = useState<QuoteResponse[]>(
    quoteData ?? []
  );
  const [filterModalShow, setFilterModalShow] = React.useState(false);
  const [searchQuotes] = useSearchQuotesMutation();
  const [searchQuery, setSearchQuery] = useState("");
  const [quoteId, setQuoteId] = useState("");

  const CustomToggle = React.forwardRef<HTMLButtonElement, CustomToggleProps>(
    ({ children, onClick }, _ref) => (
      <div className="position-relative">
        <Button variant="link" onClick={onClick} className="p-0">
          <BsThreeDotsVertical size={25} />
          {children}
        </Button>
      </div>
    )
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [filterCriteria, setFilterCriteria] = useState("");
  const pageSize = 10; // Number of items to display per page
  const totalPages = props.quoteData?.length
    ? Math.ceil(props.quoteData.length / pageSize)
    : 0;

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const renderPageItems = () => {
    const pageItems = [];

    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const handleEditAction = async (quoteId: number) => {
    try {
      let response = await getQuoteById(quoteId).unwrap();
      setConvertModalShow(true);
      setSelectedQuote(response.data[0]);
    } catch (e) {
      alert(`error, ${e}`);
    }
  };

  function formatQueryString(form: Map<string, string>): string {
    let queryString: string = "";

    form.forEach((value: string, key: string) => {
      if (value !== "") {
        queryString += queryString === "" ? "?" : "&";
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}T00:00:00.000`;
      }
    });
    // For debugging purposes
    return queryString;
  }

  const handleFilter = async () => {
    const form: Map<string, string> = new Map(Object.entries(filterCriteria));
    const queryString = formatQueryString(form);
    // Call the filterPolicy mutation with the filter criteria
    const response = await filterQuotes(queryString).unwrap();
    // Update the filteredPolicyData state with the filtered data
    setFilteredData(response?.data ?? []);
    setFilterModalShow(false);
  };

  const exportFilteredData = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredData);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "filtered_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data: any) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((item: any) => Object.values(item).join(","));
    return [header, ...rows].join("\n");
  };

  const handleSelectPolicy = (quote: any) => {
    if (quote && quote.length > 0) {
      const selectedQuote = quote[0];
      setQuoteId(selectedQuote?.id);
      setSelectedQuote(selectedQuote);
      setSearchQuery(selectedQuote?.transactionId);

      if (selectedQuote && selectedQuote.transactionId.trim() === "") {
        const filteredData =
          quoteData?.filter((data) => data.id === selectedQuote.id) ?? [];
        setFilteredData(filteredData);
      }
    }
  };

  const handleInputChange = (
    text: any,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const transactionId: string = e.target.value;
    setSearchQuery(transactionId);

    if (transactionId.trim() !== "") {
      // Update the filtered data based on the search query
      const filteredData =
        quoteData?.filter((data) =>
          data.transactionId
            ?.toLowerCase()
            .includes(transactionId.toLowerCase())
        ) ?? [];
      setFilteredData(filteredData);
    } else {
      // If the search query is empty, show all quote data
      setFilteredData(quoteData ?? []);
    }
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      // If search query is empty, show all quote data
      setFilteredData(quoteData || []);
    } else {
      // If search query is not empty, filter the quote data based on the query
      const filteredData =
        quoteData?.filter((data) =>
          data?.transactionId?.includes(searchQuery)
        ) || [];
      setFilteredData(filteredData);
    }
  }, [quoteData, searchQuery]);

  return (
    <div className="p-4 main-card-bg ">
      <div className="d-flex justify-content-between mb-3">
        <div className="w-25">
          <InputGroup className="mb-3">
            <FloatingLabel label="">
              <Typeahead
                id="searchQuote"
                labelKey="transactionId"
                options={quoteData || []}
                placeholder="Search by transaction ID"
                size="lg"
                onChange={handleSelectPolicy}
                onInputChange={handleInputChange}
                inputProps={{
                  className: "my-custom-classname rounded-0 ",
                  style: {
                    fontSize: "15px",
                  },
                }}
              />
            </FloatingLabel>
          </InputGroup>
        </div>
        <div>
          <Button
            variant="light"
            className="px-4 shadow-lg rounded-0 border-0 "
            onClick={() => setFilterModalShow(true)}
          >
            Filter
          </Button>
          <Button
            variant="light"
            className="px-4 shadow-lg mx-2 rounded-0 border-0 "
            onClick={() => exportFilteredData()}
          >
            Export
          </Button>
        </div>
      </div>
      <Card className="policy-table-card bg-white border-0 rounded-0 shadow-lg ">
        <Card.Body>
          <Table className="policy-table">
            <thead className="sticky-header py-3">
              <tr className="table-body">
              <th>Customer Name </th>
                <th>Customer Number </th>
                <th>Currency </th>
                <th>Transaction ID </th>
                <th>Asset ID </th>
                <th>Created Date</th>
                <th className="text-end">Action </th>
              </tr>
            </thead>
            <tbody className="text-muted  table-body">
              <>
                {filteredData && filteredData?.length !== 0 ? (
                  <>
                    {filteredData
                      ?.slice(startIndex, endIndex)
                      .map((data, key) => (
                        <tr key={key}>
                          <td className="text-start">{data?.customer?.name || "N/A"}</td>
                          <td className="text-start">{data.customerMsisdn}</td>
                          <td className="text-start">{data.currency}</td>
                          <td className="text-start">{data.transactionId}</td>
                          <td className="text-start">{data.assetId}</td>
                          <td className="text-start">{data.createdDate}</td>
                          <td className="text-end">
                            <Dropdown>
                              <Dropdown.Toggle
                                as={CustomToggle}
                              ></Dropdown.Toggle>
                              <Dropdown.Menu
                                title=""
                                className="dropdown-menu position-absolute"
                              >
                                <Dropdown.Item
                                  onClick={() => handleEditAction(data.id)}
                                >
                                  Convert Quote to Policy
                                </Dropdown.Item>
                                {/* Other dropdown items */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={6}
                      className="text-center fw-normal fs-2 border-0"
                    >
                      <div>
                        <AiOutlineFileExcel
                          className=""
                          style={{ fontSize: "100px", marginTop: "50px" }}
                        />
                      </div>
                      No Data Found
                    </td>
                  </tr>
                )}
              </>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <div className="bg-light mt-2 ">
        <div className="d-flex justify-content-end ">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {renderPageItems()}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </div>
      </div>
      <ConvertQuote
        show={convertModalShow}
        onHide={() => setConvertModalShow(false)}
        selectedQuote={selectedQuote}
        onConvertQuote={() => {}}
      />
      <FilterQuote
        show={filterModalShow}
        onHide={() => setFilterModalShow(false)}
        filterCriteria={filterCriteria}
        setFilterCriteria={setFilterCriteria}
        handleFilter={handleFilter}
      />
    </div>
  );
};

export default QuoteDataTable;
