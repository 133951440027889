import { FC, ReactNode, useEffect } from "react";
import React, { useState } from "react";
import { FloatingLabel, InputGroup, Pagination } from "react-bootstrap";
import { Card, Table, Button, Dropdown } from "react-bootstrap";
import FilterAndExportPolicy from "./modals/FilterAndExportPolicyModal";
import { PolicyResponse } from "../../../../../model/response/policy.response";
import { BsThreeDotsVertical } from "react-icons/bs";
import DeletePolicy from "./modals/DeletePolicy";
import { toast } from "react-toastify";
import {
  useDeletePolicyMutation,
  useGetPolicyByIdMutation,
  useSearchPolicyMutation,
  useFilterPolicyMutation,
} from "../../../../../services/policy/policy.service";
import { PolicyRequest } from "../../../../../model/request/policy.request";
import AddPolicyModal from "./modals/AddPolicyModal";
import { Typeahead } from "react-bootstrap-typeahead";
import { AiOutlineFileExcel } from "react-icons/ai";

type PolicyTableProps = {
  policyData?: PolicyResponse[];
};
interface CustomToggleProps {
  children: ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const PolicyTable: FC<PolicyTableProps> = (props) => {
  const [deletePolicy] = useDeletePolicyMutation();
  const [selectedPolicyId, setSelectedPolicyId] = useState<number | null>(null);
  const [getPolicyById] = useGetPolicyByIdMutation();
  const [searchQuery, setSearchQuery] = useState("");
  const [policyId, setPolicyId] = useState("");
  const [searchPolicy, { data }] = useSearchPolicyMutation();
  const [filteredPolicyData, setFilteredPolicyData] = useState<
    PolicyResponse[]
  >(props.policyData ?? []);
  const [filterPolicy, { data: filteredData }] = useFilterPolicyMutation();
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState<PolicyRequest>();
  const [filterModalShow, setFilterModalShow] = React.useState(false);
  const [filterCriteria, setFilterCriteria] = useState("");

  const handleCancelDelete = () => {
    setSelectedPolicyId(null);
    setDeleteModalShow(false);
  };

  const handleDeleteAction = (policyId: number) => {
    setSelectedPolicyId(policyId);
    setDeleteModalShow(true);
  };

  const handleConfirmDelete = async () => {
    try {
      let response = await deletePolicy(selectedPolicyId);
      setSelectedPolicyId(null);

      toast("Policy deleted successfully");
      setDeleteModalShow(false);
    } catch (error) {
      console.log("Error deleting policy:", error);
      setDeleteModalShow(false);
    }
  };

  const handleEditAction = async (policyId: number) => {
    toast.loading("loading...");

    try {
      let response = await getPolicyById(policyId).unwrap();

      if (Object.keys(response).includes("code")) {
        let { code, data } = response;
        if (code === "00" && data && data.length > 0) {
          const responseData = data[0];

          if (responseData && responseData.policyPeriod) {
            const policyPeriod = responseData.policyPeriod[0];
            const customer = responseData.customer;

            let policyToUpdate: PolicyRequest = {
              ...responseData,
              customerId: customer?.id,
              effectiveDate: policyPeriod.effectiveDate,
              expiryDate: policyPeriod.expiryDate,
              insuranceDeliveryDate: policyPeriod.insuranceDeliveryDate,
              lead: policyPeriod.lead,
              policyStatus: policyPeriod.policyStatus,
              remarks: policyPeriod.remarks,
            };
            setSelectedPolicy(policyToUpdate);
            setEditModalShow(true);
          }
          return;
        }
      }
    } catch (e) {
    } finally {
      toast.dismiss();
    }
  };

  const handleAddPolicy = async (policy: PolicyRequest) => {
    try {
      toast("Policy added successfully");
      setEditModalShow(false);
    } catch (e) {
      toast("Failed adding policy, please try again.");
    }
  };

  const CustomToggle = React.forwardRef<HTMLButtonElement, CustomToggleProps>(
    ({ children, onClick }, _ref) => (
      <div className="position-relative">
        <Button variant="link" onClick={onClick} className="p-0">
          <BsThreeDotsVertical size={25} />
          {children}
        </Button>
      </div>
    )
  );

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items to display per page
  const totalPages = props.policyData?.length
    ? Math.ceil(props.policyData.length / pageSize)
    : 0;

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const renderPageItems = () => {
    const pageItems = [];

    for (let i = 1; i <= totalPages; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageItems;
  };

  // Calculate the start and end indices based on the current page and page size
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const handleSelectPolicy = (policy: any) => {
    if (policy && policy.length > 0) {
      const selectedPolicy = policy[0];
      setPolicyId(selectedPolicy?.id);
      setSelectedPolicy(selectedPolicy);
      setSearchQuery(selectedPolicy?.policyNumber);

      if (searchQuery.trim() === "") {
        const filteredData =
          props.policyData?.filter((data) => data.id === selectedPolicy?.id) ??
          [];

        setFilteredPolicyData(filteredData);
      }
    }
  };

  const handleInputChange = (
    text: any,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const policyNumber: string = e.target.value;
    setSearchQuery(policyNumber);
    if (policyNumber.trim() !== "") {
      searchPolicy(policyNumber).unwrap();
      const filteredData =
        props.policyData?.filter((data) =>
          data.policyNumber.toLowerCase().includes(searchQuery.toLowerCase())
        ) ?? [];
      setFilteredPolicyData(filteredData);
    } else {
      setFilteredPolicyData(props.policyData ?? []);
    }
  };

  function formatQueryString(form: Map<string, string>): string {
    let queryString: string = "";

    form.forEach((value: string, key: string) => {
      if (value !== "") {
        queryString += queryString === "" ? "?" : "&";
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}`;
      }
    });
    return queryString;
  }

  const handleFilter = async () => {
    const form: Map<string, string> = new Map(Object.entries(filterCriteria));
    const queryString = formatQueryString(form);
    // Call the filterPolicy mutation with the filter criteria
    const response = await filterPolicy(queryString).unwrap();
    // Update the filteredPolicyData state with the filtered data
    setFilteredPolicyData(response?.data ?? []);
    setFilterModalShow(false);
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      // If search query is empty, show all customer data
      setFilteredPolicyData(props.policyData || []);
    } else {
      // If search query is not empty, filter the customer data based on the query
      const filteredData =
        props.policyData?.filter((data) =>
          data.policyNumber.includes(searchQuery)
        ) || [];
      setFilteredPolicyData(filteredData);
    }
  }, [props.policyData, searchQuery]);

  const exportFilteredData = () => {
    const csvContent =
      "data:text/csv;charset=utf-8," + convertToCSV(filteredPolicyData);

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "filtered_data.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  };

  const convertToCSV = (data: any) => {
    const header = Object.keys(data[0]).join(",");
    const rows = data.map((item: any) => Object.values(item).join(","));
    return [header, ...rows].join("\n");
  };

  return (
    <div className="p-4 main-card-bg ">
      <div className="d-flex justify-content-between mb-3">
        <div className="w-25">
          <InputGroup className="mb-3">
            <FloatingLabel label="">
              <Typeahead
                id="searchPolicy"
                labelKey="policyNumber"
                options={data?.data || []}
                placeholder="Search by policy number"
                size="lg"
                onChange={handleSelectPolicy}
                onInputChange={handleInputChange}
                inputProps={{
                  className: "my-custom-classname rounded-0 ",
                  style: {
                    fontSize: "15px",
                  },
                }}
              />
            </FloatingLabel>
          </InputGroup>
        </div>
        <div>
          <Button
            variant="light"
            className="px-4 shadow-lg rounded-0 border-0 "
            onClick={() => setFilterModalShow(true)}
          >
            Filter
          </Button>
          <Button
            variant="light"
            className="px-4 shadow-lg mx-2 rounded-0 border-0 "
            onClick={() => exportFilteredData()}
          >
            Export
          </Button>
        </div>
      </div>
      <Card className="policy-table-card bg-white border-0 rounded-0 shadow-lg ">
        <Card.Body className="table-body">
          <Table className="policy-table">
            <thead className="sticky-header py-3">
              <tr className="table-body">
                <th>Policy Number</th>
                <th>Insurance Company</th>
                <th>Transaction Type</th>
                <th>Policy Type</th>
                <th>Premium Payable</th>
                <th>Premium Paid</th>
                <th>Lead</th>
                <th>Date of Request</th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>
            <tbody className="text-muted table-body">
              <>
                {filteredPolicyData && filteredPolicyData?.length !== 0 ? (
                  <>
                    {filteredPolicyData
                      ?.slice(startIndex, endIndex)
                      .map((data, key) => (
                        <tr className="" key={key}>
                          <td>{data.policyNumber}</td>
                          <td className="w-25"> {data.insuranceCompany} </td>
                          <td> {data.transactionType} </td>
                          <td> {data.policyType} </td>
                          <td> {data.premiumPayable} </td>
                          <td> {data.premiumPaid} </td>
                          <td> {data.lead} </td>
                          <td className="text-center">
                            {" "}
                            {data.dateOfRequest}{" "}
                          </td>
                          <td className="text-end">
                            <Dropdown>
                              <Dropdown.Toggle
                                as={CustomToggle}
                              ></Dropdown.Toggle>
                              <Dropdown.Menu
                                title=""
                                className="dropdown-menu position-absolute"
                              >
                                <Dropdown.Item
                                  onClick={() => handleEditAction(data.id)}
                                >
                                  Edit Policy
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleDeleteAction(data.id)}
                                >
                                  Delete Policy
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={12}
                      className="text-center fw-normal fs-2 border-0"
                    >
                      <div>
                        <AiOutlineFileExcel
                          className=""
                          style={{ fontSize: "100px", marginTop: "50px" }}
                        />
                      </div>
                      No Data Found
                    </td>
                  </tr>
                )}
              </>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <div className="bg-light mt-2 ">
        <div className="d-flex justify-content-end ">
          <Pagination>
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            />
            {renderPageItems()}
            <Pagination.Next
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            />
          </Pagination>
        </div>
      </div>

      <FilterAndExportPolicy
        show={filterModalShow}
        onHide={() => setFilterModalShow(false)}
        filterCriteria={filterCriteria}
        setFilterCriteria={setFilterCriteria}
        handleFilter={handleFilter}
      />
      <DeletePolicy
        show={deleteModalShow}
        onHide={() => handleCancelDelete()}
        onDelete={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />

      <AddPolicyModal
        show={editModalShow}
        onHide={() => setEditModalShow(false)}
        onAddPolicy={handleAddPolicy}
        isEditMode={true}
        selectedPolicy={selectedPolicy}
      />
    </div>
  );
};

export default PolicyTable;
