import { IconType } from "react-icons";
import {
  BsDatabaseFill,
  BsFillFileEarmarkFill,
  BsFolderFill,
  BsGraphUpArrow,
  BsGridFill,
  BsPeopleFill,
} from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";

type MenuItem = {
  title: string;
  route: string;
  icon: IconType;
};
export class AppConstants {
  static baseUrl = process.env.REACT_APP_BASE_URL;
  static sideBarMenu: MenuItem[] = [
    { title: "Dashboard", route: "/home", icon: BsGridFill },
    { title: "Policies", route: "/policies", icon: BsFillFileEarmarkFill },
    { title: "Customers", route: "/customers", icon: BsPeopleFill },
    { title: "Commission", route: "/commission", icon: BsGraphUpArrow },
    { title: "Claims", route: "/claims", icon: BsFolderFill },
    { title: "Quotes", route: "/quote", icon: BsDatabaseFill },
    { title: "Payments", route: "/payment", icon: FaMoneyBillWave },
  ];
}
