import { useEffect, useState } from "react";
import { Col, Row, Card, Button } from "react-bootstrap";
import { BsGraphUpArrow, BsFolderFill } from "react-icons/bs";
import { useGetAllClaimsQuery } from "../../../../../services/claim/claim.service";
import { useGetAllCommissionQuery } from "../../../../../services/commission/commission.service";
import { useNavigate } from "react-router-dom";
import { useGetAllCustomersQuery } from "../../../../../services/customer/customer.service";
import {BsPeopleFill} from "react-icons/bs";

function TotalCards() {
  const { data: totalCommissions } = useGetAllCommissionQuery();
  const [commission, setCommission] = useState(0);
  const [claim, setClaim] = useState(0);
  const [customer, setCustomer] = useState(0);
  const navigate = useNavigate();
  const { data: totalClaims } = useGetAllClaimsQuery();
  const { data: totalCustomers } = useGetAllCustomersQuery();

  useEffect(() => {
    const Commissions = totalCommissions?.data.length;
    setCommission(Commissions ?? 0);

    const Claims = totalClaims?.data.length;
    setClaim(Claims ?? 0);

    const Customer = totalCustomers?.data.length;
    setCustomer(Customer ?? 0);
  }, [totalCommissions, totalClaims, totalCustomers]);

  const cardDetails = [
    {
      cardTitle: "Total Commission",
      cardBody: "lorem insdsd huwwda dsflasieq loremnc",
      cardCount: `${commission}`,
      cardIcon: <BsGraphUpArrow className="fs-1 fw-bold  text-light  " />,
      path: "/commission"
    },
    {
      cardTitle: "Total Claim",
      cardBody: "lorem insdsd huwwda dsflasieq loremnc",
      cardCount: `${claim}`,
      cardIcon: <BsFolderFill className="fs-1 fw-bold text-light  " />,
      path: "/claims"
    },
    {
      cardTitle: "Total Customer",
      cardBody: "lorem insdsd huwwda dsflasieq loremnc",
      cardCount: `${customer}`,
      cardIcon:  <BsPeopleFill className="fs-1 fw-bold text-light  " />,
      path:"/customers"
    },
  ];
  return (
    <div>
      <Row className="  gy-3 gx-4   align-items-stretch">
        {cardDetails.map((data, item) => (
          <Col md={12} key={item} className="mb-5">
            <Card className="rounded-0 shadow-lg border-0">
              <div className="d-flex justify-content-between ">
                <div className="py-3 px-4">
                  <h4>{data.cardTitle}</h4>

                  <span className="text-muted fw-light">{data.cardBody}</span>
                  <p className="text-primary fs-2 fw-semibold mt-3">
                    {data.cardCount}
                  </p>
                </div>
                <Button
                  variant="secondary"
                  onClick={() => navigate(data.path)}
                  className="border-0 shadow-lg rounded-0"
                >
                  <div className=" px-5 d-flex align-items-center">
                    <div className="icon-position ">{data.cardIcon}</div>
                  </div>
                </Button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>

    // dashboard-card-icon-bg
  );
}

export default TotalCards;
