import {
  Col,
  FloatingLabel,
  Form,
  Row,
  Button,
  Spinner,
} from "react-bootstrap";
import { FC, useState, ChangeEvent, useEffect } from "react";
import { PolicyRequest } from "../../../../../../model/request/policy.request";
import { toast } from "react-toastify";
import {
  useAddPolicyMutation,
  useUpdatePolicyByIdMutation,
} from "../../../../../../services/policy/policy.service";
import { Leads } from "../../../../../shared/components/Leads";

type ResidualInfoFormProps = {
  onSubmitForm: (data: PolicyRequest) => void;
  previousTab: () => void;
  onHide: () => void;
  policy?: PolicyRequest | {};
  dataForForm: any;
  isEditMode?: boolean | false;
  button: string | "";
};

const ResidualInfoForm: FC<ResidualInfoFormProps> = (props) => {
  const { isEditMode, policy } = props;
  const [addPolicy] = useAddPolicyMutation();
  const [upadtePolicyById] = useUpdatePolicyByIdMutation();
  const [formData, setFormData] = useState(props.dataForForm);
  const [check, setCheck] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleFormChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Check if the changed field belongs to policyPeriod
    // if (name.startsWith("policyPeriod.")) {
    //   const policyPeriodField = name.split(".")[1];
    //   setFormData((prevFormData: any) => ({
    //     ...prevFormData,
    //     policyPeriod: {
    //       ...prevFormData.policyPeriod,
    //       [policyPeriodField]: value,
    //     },
    //   }));
    // } else {
    //   setFormData((prevFormData: any) => ({
    //     ...prevFormData,
    //     [name]: value,
    //     employee: { id: "1", name: "yaw" },
    //   }));
    // }

    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setFormData({
      ...formData,
      [name]: value,
      employee: { id: 13, name: "yaw" },
    });
  };

  useEffect(() => {
    // Fetch claim data when the component mounts
    if (isEditMode) {
      setCheck(true);
      // Adjust this line based on your useGetClaimByIdMutation API usage
    }
  }, []);

  const handleAdd = () => {
    props.onSubmitForm(formData);
  };

  const [loading, setLoading] = useState(false);

  const handleAddPolicy = async (policy: PolicyRequest) => {
    setLoading(true);
    const requiredFields: string[] = [
      "effectiveDate",
      "remarks",
      "expiryDate",
      "policyStatus",
      "insuranceDeliveryDate",
      "lead",
    ];

    const errors: { [key: string]: boolean } = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = true;
      }
    });

    // Set the field errors
    setFieldErrors(errors);

    if (Object.keys(errors).length === 0) {
      // Reset the field errors
      setFieldErrors({});

      if (check === true) {
        console.log("is edit mode is true");
        try {
          let response = await upadtePolicyById(policy);
          toast("Policy Updated successfully");
          props.onHide();
          setLoading(false);
        } catch (e) {
          toast("Failed updating policy, please try again.");
        }
      } else {
        try {
          let response = await addPolicy(policy);
          toast("Policy added successfully");
          props.onHide();
          setLoading(false);
        } catch (e) {
          toast("Failed adding policy, please try again.");
        }
      }
    }
  };

  const previousTab = () => {
    props.previousTab();
  };

  return (
    <Form className="modal-forms px-5">
      <Row className="pb-2 gx-3">
        <Form.Group as={Col}>
          <FloatingLabel label=" Effective Date">
            <Form.Control
              type="date"
              name="effectiveDate"
              className="rounded-0 border-primary"
              onChange={handleFormChanged}
              value={formData.effectiveDate}
              required
              isInvalid={fieldErrors.effectiveDate}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Remark">
            <Form.Control
              type="text"
              name="remarks"
              className="rounded-0 border-primary"
              onChange={handleFormChanged}
              value={formData.remarks}
              required
              isInvalid={fieldErrors.remarks}
            />
          </FloatingLabel>
        </Form.Group>
      </Row>

      <Row className="gx-3 pb-2">
        <Form.Group as={Col}>
          <FloatingLabel label="Expiry date">
            <Form.Control
              type="date"
              className="rounded-0 border-primary"
              name="expiryDate"
              onChange={handleFormChanged}
              value={formData.expiryDate}
              required
              isInvalid={fieldErrors.expiryDate}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col}>
          <FloatingLabel label="Policy Status">
            <Form.Control
              as="select"
              type="text"
              className="rounded-0 border-primary"
              name="policyStatus"
              onChange={handleFormChanged}
              value={formData.policyStatus}
              required
              isInvalid={fieldErrors.policyStatus}
            >
              <option value="" selected disabled>
                -- select policy status --
              </option>
              <option value="active">Active</option>
              <option value="inactive">Inactive </option>
            </Form.Control>
          </FloatingLabel>
        </Form.Group>
      </Row>

      <Row className="gx-3 pb-2">
        <Form.Group as={Col}>
          <FloatingLabel label="Insurance Delivery Date">
            <Form.Control
              type="date"
              className="rounded-0 border-primary"
              name="insuranceDeliveryDate"
              onChange={handleFormChanged}
              value={formData.insuranceDeliveryDate}
              required
              isInvalid={fieldErrors.insuranceDeliveryDate}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group as={Col}>
          <FloatingLabel label="Lead">
            <Form.Control
              as="select"
              type="text"
              className="rounded-0 border-primary"
              name="lead"
              onChange={handleFormChanged}
              value={formData.lead}
              required
              isInvalid={fieldErrors.lead}
            >
              <option value="" selected disabled>
                -- Select Lead --{" "}
              </option>
              {Leads.map((lead, index) => (
                <option value={index}>{lead} </option>
              ))}
            </Form.Control>
          </FloatingLabel>
        </Form.Group>
      </Row>

      <div className="text-end">
        <Button
          variant="light"
          className="rounded-0 px-5 mx-2 "
          onClick={() => previousTab()}
        >
          Previous
        </Button>

        <Button
          className="rounded-0 px-5"
          onClick={(e) => handleAddPolicy(formData)}
        >
          {loading ? (
            <Spinner
              style={{ fontSize: "2px", height: "20px", width: "20px" }}
            />
          ) : (
            <span>{props.button} Policy</span>
          )}
        </Button>
      </div>
    </Form>
  );
};

export default ResidualInfoForm;
